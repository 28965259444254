import React from "react";
import DashboardLayout from "../component/Dashboard/DashboardLayout";
import Transactions from "../component/Transactions";
import "./dashboard.css";

export default function Transactionshom() {
  return (
    <div className="bgImage">
      <DashboardLayout>
        
        <Transactions />
      </DashboardLayout>
    </div>
  );
}
