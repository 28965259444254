import { CURRENCIES_API, CURRENCIES, BINANCES_CURRENCIES_API } from "../helpers/data";

const SYMBOLS = CURRENCIES.map(c => c.symbol)

export const fetchCurrencies = async () => {
    const res = await fetch(CURRENCIES_API)
    const currencies = await res.json()
    //console.log(" currencies", currencies)
    return currencies.filter(c => SYMBOLS.includes(c.symbol))
}

export const fetchCurrenciesBinance = async () => {
    const res = await fetch(BINANCES_CURRENCIES_API)
    let currencies = await res.json()
    const btcBase = currencies.find(c => c.symbol === "BTCEUR");
    //console.log("btcBase", btcBase)
    currencies = currencies.map(c => {
        if (c.symbol !== "BTCEUR" && c.symbol.startsWith("BTC")) {
            return {
                ...c,
                symbol: `${c.symbol.split("BTC")[1]}EUR`,
                price: btcBase.price / c.price
            }
        } else {
            return {
                ...c,
                price: parseFloat(c.price)
            }
        }
    });
    //console.log(" data", currencies);
    return currencies//.filter(c => SYMBOLS.includes(c.symbol))
}