import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TransactionStatus from "../Transactions/TransactionStatus";
import {
  formatHash,
  formatNumber,
  formatToFrenchNumber,
  formatTransactionStatus,
  sortDataByCreatedAt,
} from "../../../helpers";
import BtnWithTooltip from "../../Utils/BtnWithTooltip";
import { TOKEN_NAME, TRANSACTION_STATUS } from "../../../helpers/data";

export default function UserTransactions({
  user,
  setUser,
  onTransactionUpdate,
}) {
  const onHashCopied = () => {
    toast.success("Hash copié!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const sortedTransactions = sortDataByCreatedAt(user.transactions);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <BtnWithTooltip tip="Retour à la liste des utilisateurs">
          <ArrowBackIosIcon
            sx={{ cursor: "pointer" }}
            onClick={() => setUser(null)}
          />
        </BtnWithTooltip>
        <Typography variant="h6">
          {user.lastName} {user.firstName}
        </Typography>
      </Box>
      <Box>
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#fff", color: "white", mt: 4 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell /* align="right" */>Quantité</TableCell>
                <TableCell /* align="right" */>Prix {TOKEN_NAME}</TableCell>
                <TableCell>Méthode de paiement</TableCell>
                <TableCell /* align="right" */>Total</TableCell>
                {/* <TableCell >Hash</TableCell> */}
                <TableCell /* align="right" */>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactions.map((transaction, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {moment(transaction.createdAt).format(
                        "DD/MM/YYYY hh:mm:ss"
                      )}
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(formatNumber(transaction.qty, 2))}
                      </Typography>{" "}
                    </TableCell>

                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(transaction.tokenPrice)}€
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography textTransform={"capitalize"}>
                        {transaction.paymentMethod}
                      </Typography>
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(
                          formatNumber(transaction.amount, 2)
                        )}
                        €
                      </Typography>{" "}
                    </TableCell>
                    {/* <TableCell >
                      <CopyToClipboard
                        text={transaction.hash}
                        onCopy={onHashCopied}
                      >
                        <Typography sx={{ cursor: "pointer" }}>
                          {formatHash(transaction.hash)}
                          <ContentCopyIcon
                            sx={{
                              fontSize: 16,
                              display: "inline-block",
                              ml: 0.2,
                            }}
                          />
                        </Typography>
                      </CopyToClipboard>
                    </TableCell> */}
                    <TableCell /* align="right" */>
                      {" "}
                      <Box
                        className="badge"
                        sx={{
                          backgroundColor:
                            transaction.status === TRANSACTION_STATUS.PENDING
                              ? "orange"
                              : transaction.status ===
                                TRANSACTION_STATUS.VALIDATED
                              ? "green"
                              : "red",
                          color: "white",
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {formatTransactionStatus(transaction.status)}{" "}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {user.transactions.length === 0 && (
            <Typography textAlign="center" color="white" m={3}>
              Aucune transaction effectuée
            </Typography>
          )}
          {/* 
        {transactions.data.length === 0 && (
          <Typography textAlign="center" m={3}>
            Aucune transaction effectuée
          </Typography>
        )} */}
        </TableContainer>
      </Box>
    </Box>
  );
}
