import { Box, Typography } from "@mui/material";
import { convertEurToLifd, formatNumber, isStableCoin } from "../../helpers";
import { CURRENCIES, TOKEN_NAME } from "../../helpers/data";
import useCurrencies from "../../hooks/store/useCurrencies";
import useRounds from "../../hooks/store/useRounds";

const CurrencySelection = ({ handleNext, setSelectedCurrency }) => {
  const handleClick = (currency) => {
    setSelectedCurrency(currency);
    handleNext();
  };

  const { currencies } = useCurrencies();

  const { rounds } = useRounds();
  const round = rounds.data.find((r) => r.stock > 0);
  const tokenPrice = round?.price || 0;

  return (
    <Box className="">
      <div style={{}} className="row">
        <div className="col-md-4">
          <div className="bar1 p-3 pb-4 mr-4">
            <Typography variant="h6" className="text-white">
              {" "}
              Veuillez sélectionner votre devise
            </Typography>
            <Typography className="text-white">
              Sélectionnez la devise que vous souhaitez utiliser pour acheter {TOKEN_NAME}
            </Typography>
          </div>
        </div>

        <div className="col-md-8 mt-md-0 mt-3">
          <Box sx={{ borderRadius: 3, backgroundColor: "#f4f6f8" }} className="p-2">
            <div className="row">
              {CURRENCIES.map((currency, index) => {
                const cur = currencies.find(
                  (c) => c.symbol === currency.symbol
                );
                const toFixed = isStableCoin(currency.symbol) ? 8 : 2;
                const text =
                  cur && tokenPrice
                    ? formatNumber(
                      1 / convertEurToLifd(cur.price, tokenPrice),
                      toFixed
                    )
                    : ".......";
                const conversionText = `1${TOKEN_NAME} = ${text} ${currency.name}`;

                return (
                  <div className="col-md-6" key={index}>
                    <Box
                      sx={{ border: "1px solid #fff", cursor: "pointer" }}
                      className="m-2 p-2 d-flex align-items-center rounded bg-white"
                      onClick={() => {
                        if (cur) {
                          handleClick({
                            ...cur,
                            name: currency.name,
                            token: currency.token,
                          });
                        }
                      }}
                    >
                      <img
                        src={currency.image}
                        alt={currency.name}
                        height={40}
                      />
                      <Typography ml={1}>
                        {currency.name} <br />
                        <b style={{ fontSize: "12px", marginTop: "100px" }}>
                          {conversionText}
                        </b>
                      </Typography>
                    </Box>
                  </div>
                );
              })}
            </div>
          </Box>
        </div>
      </div>

      {/* <Button onClick={handleClick}>NEXT</Button> */}
    </Box>
  );
};

export default CurrencySelection;
