import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getRounds } from "../../../api/rounds";
import { spreadAttributes } from "../../../helpers";
import { LoadingCard } from "../../Utils/Loaders";
import RoundCard from "./RoundCard";
import RoundForm from "./RoundForm";
import RefreshIcon from '@mui/icons-material/Refresh';
import BtnWithTooltip from "../../Utils/BtnWithTooltip";

export default function Rounds() {
  const [rounds, setRounds] = useState({
    data: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  const fetchRounds = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await getRounds();
      //console.log(data);
      setRounds({
        ...data,
        data: data.data.map((el) => spreadAttributes(el)),
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [])

  useEffect(() => {
    fetchRounds();
  }, [fetchRounds]);

  const onSubmit = (round) => {
    const roundExist = rounds.data.find((r) => r._id === round._id);
    //if round id is present, update the round  else create a new round
    if (roundExist) {
      setRounds({
        ...rounds,
        data: rounds.data.map((r) =>
          r._id === round._id ? spreadAttributes(round) : r
        ),
      });
    } else {
      setRounds({
        ...rounds,
        data: [...rounds.data, spreadAttributes(round)],
      });
    }
  };

  const onDelete = (id) => {
    setRounds({
      ...rounds,
      data: rounds.data.filter((r) => r._id !== id),
    });
  };

  return (
    <Box backgroundColor="#f4f6f8" p={3} sx={{ minHeight: 300 }}>
      <Box display="flex" alignItems="center">
        <Typography variant="h5" mr={2}>
          Rounds
        </Typography>
        <BtnWithTooltip tip="Actualiser">
          <RefreshIcon sx={{ color: "#333", cursor: "pointer" }} onClick={fetchRounds} />
        </BtnWithTooltip>
      </Box>
      {isLoading ? (
        <Grid container spacing={3} mt={0.4}>
          {Array.from({ length: 3 }).map((_, i) => (
            <Grid key={i} item lg={4} md={6} xs={12}>
              <LoadingCard />
            </Grid>
          ))}
        </Grid>
      ) : rounds.data.length > 0 ? (
        <Grid container spacing={3} mt={0.4}>
          {rounds.data.map((r, i) => {
            return (
              <Grid key={i} item lg={4} md={6} xs={12}>
                <RoundCard
                  round={r}
                  number={i + 1}
                  onEdit={onSubmit}
                  onDelete={onDelete}
                />
              </Grid>
            );
          })}
          <Grid item lg={4} md={6} xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
                height: "100%",
                py: 3,
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RoundForm
                trigerComponent={
                  <Button
                    sx={{ textDecoration: "none", fontSize: 12, px: 2, py: 1 }}
                    className="bgGradient"
                    variant="contained"
                    disableElevation
                  >
                    Ajouté un autre round
                  </Button>
                }
                onSubmit={onSubmit}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box mt={3}>
          <RoundForm
            trigerComponent={
              <Button
                sx={{ textDecoration: "none", fontSize: 12, px: 8, py: 1 }}
                className="btnLogin bgGradient"
                variant="contained"
                disableElevation
              >
                Ajouté votre premier round
              </Button>
            }
            onSubmit={onSubmit}
          />
        </Box>
      )}
    </Box>
  );
}
