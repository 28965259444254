import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react'
import { deleteRound } from '../../../api/rounds';
import MUIModal from '../../Utils/MuiModal';
import { toast } from "react-toastify";

export default function DeleteRound({ triggerComponent, round, onDelete }) {
    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleDelete = async () => {
        try {
            setSubmitting(true);
            const { data } = await deleteRound(round._id);
            //console.log("data", data)
            if (data) {
                onDelete(round._id);
                toast.success("Round supprimé avec succès!",
                    {
                        position: "bottom-center",
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
                setSubmitting(false)
                handleClose();
            }
        } catch (error) {
            console.error(error);
            setSubmitting(false);
        }
    }

    return (
        <>
            <Box onClick={handleOpen}>{triggerComponent}</Box>
            <MUIModal open={open} maxWidth="xs" handleClose={handleClose}>
                <Box sx={{ py: 2 }}>
                    <Typography mb={1} color="white" variant="h5">
                        Suppression de round
                    </Typography>
                    <Typography color="white" my={1}>
                        Êtes-vous sûr de vouloir supprimer ce round?
                    </Typography>
                    <Box>
                        <Button
                            className="mt-4 text-uppercase"
                            variant="outlined"
                            //color="error"
                            onClick={handleClose}
                            sx={{ mr: 2 }}
                        >
                            NON
                        </Button>
                        <Button
                            className="mt-4 text-uppercase"
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                            disabled={submitting}
                        >
                            {submitting ? "Suppression..." : "OUI"}
                        </Button>
                    </Box>
                </Box>
            </MUIModal>
        </>
    )
}
