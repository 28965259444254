import { createSlice } from "@reduxjs/toolkit";
import { getActiveRound, getRounds as fetchRounds } from "../api/rounds";
import { spreadAttributes } from "../helpers";
import { STATUS } from "../helpers/data";

export const rounds = createSlice({
  name: "rounds",
  initialState: {
    rounds: {
      data: [],
    },
    activeRound: null,
    status: "",
  },
  reducers: {
    setRounds: (state, action) => {
      state.rounds = {
        data: action.payload,
      };
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setActiveRound: (state, action) => {
      state.activeRound = action.payload;
    },
    addOrUpdateRound: (state, action) => {
      const payload = spreadAttributes(action.payload);
      const { _id } = payload;
      const round = state.rounds.data.find((w) => w._id === _id);
      if (round) {
        state.rounds.data[state.rounds.data.indexOf(round)] = payload;
      } else {
        state.rounds.data.push(payload);
      }
    },
    addRound: (state, action) => {
      state.rounds.data.push(action.payload);
    },
    updateRound: (state, action) => {
      state.rounds.data = state.rounds.data.map((round) => {
        if (round._id === action.payload._id) {
          return action.payload;
        }
        return round;
      });
    },
    deleteRound: (state, action) => {
      state.rounds.data = state.rounds.data.filter(
        (round) => round._id !== action.payload
      );
    },
  },
});

export const {
  setRounds,
  setStatus,
  setActiveRound,
  addRound,
  updateRound,
  deleteRound,
  addOrUpdateRound,
} = rounds.actions;
export default rounds.reducer;

export const getRounds = () => async (dispatch) => {
  //if (rounds.data.length === 0) {
  dispatch(setStatus(STATUS.LOADING));
  //}
  try {
    const { data } = await fetchRounds();
    if (data.success) {
      //console.log("cur", data)
      dispatch(setRounds(data.data));
      dispatch(setStatus(STATUS.LOADED));
    }
  } catch (error) {
    dispatch(setStatus(STATUS.ERROR));
  }
};

export const getRoundActive = () => async (dispatch) => {
  //if (rounds.data.length === 0) {
  //dispatch(setStatus(STATUS.LOADING));
  //}
  try {
    const { data } = await getActiveRound();
    if (data.success) {
      //console.log("cur", data);
      dispatch(setActiveRound(data.data));
    }
  } catch (error) {
    dispatch(setStatus(STATUS.ERROR));
  }
};
