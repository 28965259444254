import { Box } from '@mui/material';
import React from 'react';
import { ProtectedByRole } from '../../component/Utils/ProtectedByRole';
import { USER_ROLE } from '../../helpers/data';
import DashboardLayout from "../../component/Dashboard/DashboardLayout";
import Rounds from '../../component/Admin/Rounds/Rounds';
import Wallets from '../../component/Admin/Wallets/Index';

export default function AdminMain() {


    return (
        <ProtectedByRole
            role={USER_ROLE.ADMIN}
            onRedirect={(role) => {
                return `/auth/login`;
            }}
        >
            <DashboardLayout>
                <Rounds />
                <Box mt={2}>
                    <Wallets />
                </Box>
            </DashboardLayout>
        </ProtectedByRole>
    );
}
