import React from "react";
import HorizontalLinearStepper from "../component/Achat";
import DashboardLayout from "../component/Dashboard/DashboardLayout";
// import DashboardCompo from "../component/DashboardCompo";
import "./dashboard.css";
import Purchase from "../component/Purchase/Index";

export default function PurchasePage() {
  return (
    <div className="bgImage">
      <DashboardLayout>
        <Purchase />
      </DashboardLayout>
    </div>
  );
}
