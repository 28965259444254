import axios from "axios";
import { API_BASE_URL } from "../helpers/data";

export const loginUser = (userData) => {
  delete axios.defaults.headers.common["Authorization"];
  return axios.post(`${API_BASE_URL}/auth/signin`, userData);
};

export const registerUser = (userData) => {
  delete axios.defaults.headers.common["Authorization"];
  return axios.post(`${API_BASE_URL}/auth/register`, userData);
};

export const sendResetPasswordLink = (data) => {
  delete axios.defaults.headers.common["Authorization"];
  return axios.post(`${API_BASE_URL}/auth/forgot-password`, data);
};

export const resetPassword = (data) => {
  return axios.post(`${API_BASE_URL}/auth/reset-password`, data);
};

export const getAuthUser = () => {
  return axios.get(
    `${API_BASE_URL}/users/me`
  );
};

export const getUsers = (params) => {
  return axios.get(
    `${API_BASE_URL}/users`, { params }
  );
};


export const updateUser = data => {
  return axios.put(`${API_BASE_URL}/users/me`, data);
}