import React from 'react'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";


export default function CopyWrapper({ text, onCopyMessage }) {

    const onCopy = () => {
        toast.success(onCopyMessage, {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            <CopyToClipboard
                text={text}
                onCopy={onCopy}
            >
                <ContentCopyIcon
                    sx={{
                        fontSize: 16,
                        display: "inline-block",
                        ml: 0.2,
                        cursor: "pointer",
                    }}
                />
            </CopyToClipboard>
        </>
    )
}
