import React, { useEffect, useRef } from "react";
import "./DashboardCompo.css";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
//import useCurrencies from "../hooks/store/useCurrencies";
import useAuth from "../hooks/store/useAuth";
import {
  calculateUserBalance,
  capitalize,
  formatNumber,
  formatToFrenchNumber,
} from "../helpers";
import useRounds from "../hooks/store/useRounds";
import { ChevronRight } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { TOKEN_NAME } from "../helpers/data";

// import { padding } from '@mui/system';

export default function DashboardCompo() {
  const sceneRef = useRef();

  //const { tokenPrice, totalTokens } = useCurrencies();
  const { user, balance, fetchUserBalance } = useAuth();
  const { rounds, getRounds } = useRounds();

  useEffect(() => {
    getRounds();
    fetchUserBalance();
  }, [getRounds]);

  //console.log("rounds", rounds);

  const round = rounds.data.find((r) => r.stock > 0);
  const roundStatus = round ? "en cours" : "terminé";

  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  return (
    <Box>
      <div className="container-fluid mb-4">
        <Box
          sx={{
            background: "linear-gradient(70deg, #1345c6, #4176ff)",
            borderRadius: 2,
            py: 1.5,
            px: 2,
          }}
          className="row"
        >
          <div
            className="col-sm-12 col-md-3 col-lg-3 col-12 d-none d-md-block text-white"
            style={{
              marginTop: 14,
            }}
          >
            <Typography>
              <b> Le round est {roundStatus}!</b>
            </Typography>
          </div>
          <div
            style={{ textAlign: "center", color: "white" }}
            className="text-center pb-1 col-sm-12 col-md-5 col-lg-6 col-12 align-self-center "
          >
            <Typography
              style={{
                fontSize: "2.125rem",
                lineHeight: " 2.5rem",
                letterSpacing: ".0073529412em",
                fontWeight: "900",
                fontFamily: "roboto, sans-serif",
              }}
              className="pa-0 justify-center display-1 font-weight-black white-text"
            >
              {formatToFrenchNumber(round?.stock, 2)}{" "}
              <span className="d-block d-md-inline">{TOKEN_NAME}</span>
            </Typography>
            <Typography
              sx={{ fontSize: 12, letterSpacing: 1 }}
              className=" overline pa-0 ma-0"
            >
              {formatNumber(round?.price, 2)}€/{TOKEN_NAME}
            </Typography>
          </div>

          <div className="pr-6 col-sm-12 col-md-4 col-lg-3 col-12 align-self-center text-white mt-md-0 mt-3">
            <Link
              style={{ textDecoration: "none", color: "white" }}
              className=""
              to="/achat"
            >
              <span className="v-btn__content bg-danger">
                <Box
                  className="text-center px-1 py-2"
                  style={{
                    border: "1px solid",
                    borderRadius: " 5px",
                  }}
                >
                  <Typography
                    fontSize={15}
                    fontWeight="bold"
                    className="font-weight-bold"
                  >
                    Acheter maintenant
                  </Typography>
                </Box>
              </span>
            </Link>
          </div>
        </Box>
      </div>

      <div className="mb-md-5 mb-4">
        <Grid container spacing={3}>
          {/* bloc1 */}
          <Grid item md={6} sm={12} sx={12} width="100%">
            <Box
              sx={{
                background: " linear-gradient(70deg, #1345c6, #4176ff)",
                margin: 0,
                borderRadius: 2,
                opacity: 0.9,
                p: 2,
              }}
            >
              <Box className="d-none d-md-flex justify-content-between">
                <Typography
                  sx={{ fontSize: 15 }}
                  className="text-white d-md-block d-none"
                >
                  <b>
                    Mes <span className="text-capitalize">{TOKEN_NAME}</span>
                  </b>
                </Typography>
                <Typography
                  component={Link}
                  to="/transactions"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 13,
                    color: "#fff",
                    fontWeight: "bold",
                    cursor: "pointer",
                    px: 1,
                    py: 0.4,
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.2)",
                      borderRadius: 2,
                    },
                  }}
                >
                  <ChevronRight sx={{ display: "inline-block", mr: 0.4 }} /> Mes
                  Transactions
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Typography
                  sx={{ fontSize: 18, textAlign: "center", color: "#fff" }}
                >
                  <b> Vous avez...</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.5rem",
                    letterSpacing: 0.1,
                    fontWeight: "900",
                    color: "#fff",
                    "@media (max-width: 992px)": {
                      fontSize: "3.5rem",
                    },
                  }}
                  className="text-center"
                >
                  {formatToFrenchNumber(balance, 2) || 0}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2.125rem",
                    letterSpacing: " .0073529412em!important",
                    fontWeight: "900",
                    color: "#fff",
                  }}
                  className="text-center"
                >
                  ${TOKEN_NAME}
                </Typography>
                <Link
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                    marginTop: 7,
                  }}
                  to="/achat"
                >
                  <Button
                    style={{
                      backgroundColor: "#1a2672",
                      fontSize: "12px",
                      width: 174,
                      borderRadius: 7,
                      justifyContent: " center",
                    }}
                    className="py-3"
                    variant="contained"
                    disableElevation
                  >
                    J'en veux plus !
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={12} sx={12} width="100%">
            <Box
              sx={{
                background: " linear-gradient(70deg, #1345c6, #4176ff)",
                margin: 0,
                borderRadius: 2,
                opacity: 0.9,
                p: 2,
                width: "100%",
              }}
            >
              <Box className="d-none d-md-flex justify-content-between">
                <Typography
                  sx={{ fontSize: 15 }}
                  className="text-white d-md-block d-none"
                >
                  <b>
                    Mes <span className="text-capitalize">{TOKEN_NAME}</span>
                  </b>
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 13,
                    color: "#fff",
                    fontWeight: "bold",
                    px: 1,
                    py: 0.6,
                  }}
                >
                  Gagner ${TOKEN_NAME}
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <Typography
                  sx={{ fontSize: 18, textAlign: "center", color: "#fff" }}
                >
                  <b>Vous avez gagné...</b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: "3.5rem",
                    letterSpacing: 0.1,
                    fontWeight: "900",
                    color: "#fff",
                    "@media (max-width: 992px)": {
                      fontSize: "3.5rem",
                    },
                  }}
                  className="text-center"
                >
                  {formatToFrenchNumber(user.totalEarnedOnReferrals) || 0}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2.125rem",
                    letterSpacing: " .0073529412em!important",
                    fontWeight: "900",
                    color: "#fff",
                  }}
                  className="text-center"
                >
                  ${TOKEN_NAME}
                </Typography>
                <Link
                  style={{
                    textDecoration: "none",
                    textAlign: "center",
                    marginTop: 7,
                  }}
                  to="/parrainage"
                >
                  <Button
                    style={{
                      backgroundColor: "#1a2672",
                      fontSize: "12px",
                      width: 174,
                      borderRadius: 7,
                      justifyContent: " center",
                    }}
                    className="py-3"
                    variant="contained"
                    disableElevation
                  >
                    Gagner ${TOKEN_NAME}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className="container-fluid">
        <div
          style={{
            backgroundColor: "#f4f6f8",
            padding: "50px",
            borderRadius: 10,
          }}
          className="row"
        >
          <div style={{ textAlign: "center" }}>
            <Typography
              className="text-center"
              variant="h6"
              textAlign="center"
              color="primary"
              marginBottom="10px"
            >
              Obtenez vos {capitalize(TOKEN_NAME)} et rejoignez le combat
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              <Button
                className="bgGradient"
                sx={{
                  margin: 2,
                  px: 3,
                  "&:hover": {
                    color: "#fff",
                  },
                }}
                variant="contained"
                disableElevation
                component={Link}
                to="/achat"
              >
                J'en veux plus !
              </Button>
              <Link to={{ pathname: "https://www.okacoin.com" }} target="_blank">
                <Button
                  style={{
                    marginTop: "1px",
                    border: "2px solid #3f74fd",
                    color: "#3f74fd",
                    backgroundColor: "#fff",
                  }}
                  className="btnc2"
                  variant="contained"
                  disableElevation
                >
                  Lire le Whitepaper
                </Button>
              </Link>
            </Box>
          </div>
        </div>
      </div>
    </Box>
  );
}
