import React, { useEffect, useRef } from "react";
import "./DashboardCompo.css";
import { Box, Typography, useMediaQuery } from "@mui/material";
import image from "../images/img.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FormControl, InputGroup } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import useAuth from "../hooks/store/useAuth";
import { calculateRefereeGain, formatToFrenchNumber, getUserReferalCodeLink } from "../helpers";
import moment from "moment";
import { TOKEN_NAME } from "../helpers/data";


export default function Parrainage() {
  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  const { user } = useAuth();
  const md = useMediaQuery("(min-width:768px)");


  const onCodeCopied = () => {
    toast.success('Lien copié avec succès!', {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const LINK_CODE = getUserReferalCodeLink(user.referalCode);

  const getCurrentTime = () => {
    const date = new Date();
    const t = moment(date).format("HH:mm");
    /* const hours = date.getHours();
    const minutes = date.getMinutes();
    //const seconds = date.getSeconds();
    const time = `${hours}:${minutes}`; */
    return t;
  }

  return (
    <Box className="gb" m={md ? 0 : -3} mb={md ? 0 : 5}>
      <div className="container">
        <div style={{ backgroundColor: "#f4f6f8", borderRadius: "8px" }} className="row border-raduis mx-0 p-3 mb-md-5 mb-4">
          <Typography variant="h6">
            Affiliation
          </Typography>
          <div>
            <div className="row my-5 affiliation-bloc-1">
              <div className="col-md-6">
                <div>
                  <Typography>
                    Pour chacun de vos affiliés, vous recevrez:
                  </Typography>
                </div>
                <div className="d-flex mt-4 align-items-md-end align-items-center">
                  <Typography className="percentage-text" variant="h1">
                    10
                    <span className="percentage-sign">%</span>
                  </Typography>
                  <Typography sx={{ ml: 2 }}>
                    du montant total de leurs
                    <br />
                    <b style={{ color: "#3f74fd" }}>
                      transactions converties en ${TOKEN_NAME}
                    </b>
                  </Typography>
                </div>
              </div>
              <div className="col-md-6 affiliation-bloc-1-img ">
                <div className="d-flex justify-content-end">
                  <img height={200} src={image} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center" }} className="mt-2 ">
            <p
              style={{
                color: "#3f74fd",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              Invitez vos amis pour gagner du {TOKEN_NAME}
            </p>
            <p>
              Partagez le lien ci-dessous pour obtenir plus de {TOKEN_NAME}
            </p>

            <div
              style={{ maxWidth: 600, margin: "auto", cursor: "pointer" }} >
              <CopyToClipboard text={LINK_CODE}
                onCopy={onCodeCopied}>
                <InputGroup className=" mb-3">
                  <FormControl
                    placeholder=""
                    aria-label="code de parrainage"
                    aria-describedby="basic-addon2"
                    value={LINK_CODE}
                  />
                  <InputGroup.Text id="basic-addon2">
                    <ContentCopyIcon />{" "}
                  </InputGroup.Text>
                </InputGroup>
              </CopyToClipboard>
            </div>


          </div>
        </div>
        <div className="row d-flex">
          <div className="col-md-4 mb-md-5 mb-4">
            <div
              style={{ textAlign: "center", padding: "20px" }}
              className=" bar1 text-white"
            >
              <PersonAddIcon className="stats-number" />
              <br /> <br />
              <p
                style={{ fontSize: "17px", textAlign: "center" }}
                className="text-center"
              >
                <b>Nombre d'affiliés</b>
              </p>
              <p style={{ marginTop: "-15px" }}>Aujourd’hui à {getCurrentTime()}</p>
              <p>
                {" "}
                <b className="stats-number">{user.referralsCounts > 0 ? formatToFrenchNumber(user.referralsCounts) : 0}</b> <br />
                Affilié{user.referralsCounts > 0 ? "s" : ""}
              </p>
            </div>
          </div>
          <div className="col-md-4 mb-md-5 mb-4">
            <div
              style={{
                textAlign: "center",
                padding: "20px",
                paddingTop: "35px",
              }}
              className=" bar1 text-white"
            >
              {" "}
              <h3>${TOKEN_NAME}</h3>
              <br />
              <p
                style={{ fontSize: "17px", textAlign: "center" }}
                className="text-center"
              >
                <b>Nombre de {TOKEN_NAME} gagnés</b>
              </p>
              <p style={{ marginTop: "-15px" }}>Aujourd’hui à {getCurrentTime()}</p>
              <p>
                {" "}
                <b className="stats-number">{formatToFrenchNumber(user.totalEarnedOnReferrals) || 0}</b> <br />
                ${TOKEN_NAME}
              </p>
            </div>
          </div>
          <div className=" col-md-4 ">
            <div
              style={{ textAlign: "center", padding: "27px 20px" }}
              className=" bar1 text-white"
            >
              <UnarchiveIcon className="stats-number" />
              <p
                style={{ fontSize: "17px", textAlign: "center" }}
                className="text-center"
              >
                {" "}
                <br />
                <b>Achetés par mes affiliés</b>
              </p>
              <p style={{ marginTop: "-15px" }}>Aujourd’hui à {getCurrentTime()}</p>
              <Typography>
                <b className="stats-number">
                  {formatToFrenchNumber(calculateRefereeGain(user.totalEarnedOnReferrals)) || 0}</b> <br />
                Affiliés
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
