import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export default function LoadingView() {
    return (
        <Box
            sx={{ height: "100vh", backgroundColor: "#fff" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <CircularProgress sx={{ color: "primary" }} />
        </Box>
    );
}
