import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { createTransaction } from "../../../api/transactions";
import { TOKEN_NAME } from "../../../helpers/data";
import useRounds from "../../../hooks/store/useRounds";
import useDisclosure from "../../../hooks/useDisclose";
import MUIModal from "../../Utils/MuiModal";
import UsersSelector from "../../Utils/UsersSelector";

export default function CreateForm({ onSubmit }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [amount, setAmount] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);

  const [selectedUser, setSelectedUser] = useState();
  const [submitting, setSubmitting] = useState();

  const { activeRound } = useRounds();

  const handleSubmit = useCallback(async () => {
    try {
      if (amount > 0 && selectedUser && tokenPrice > 0) {
        setSubmitting(true);
        const submitData = {
          amount,
          user: selectedUser,
          tokenPrice
        };
        const { data } = await createTransaction(submitData);
        if (data.success) {
          setSubmitting(false);
          onClose();
          toast.success("Transaction ajoutée avec succès", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          onSubmit?.(data.data);
          setAmount(0);
          setSelectedUser();
          setTokenPrice(0);
        }
      } else {
        toast.error("Veuillez remplir tous les champs", {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      console.error(error);
    } finally {
      setSubmitting(false)
    }
  }, [amount, selectedUser, tokenPrice, onSubmit, onClose]);

  const qty = amount > 0 && tokenPrice > 0 ? amount / tokenPrice : 0;

  return (
    <div>
      <Button
        sx={{ textDecoration: "none", fontSize: 12, px: 2, py: 1 }}
        className="__bg-gradient text-white"
        variant="contained"
        disableElevation
        onClick={onOpen}
      >
        Ajouter une transaction
      </Button>
      <MUIModal open={isOpen} handleClose={onClose}>
        <Box>
          <Typography variant="h5" textAlign="center" mb={3}>
            Ajouter une transaction
          </Typography>
          <Box mb={3}>
            <UsersSelector
              value={selectedUser}
              onChange={(v) => setSelectedUser(v)}
              limit={12}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label={`Montant en €`}
              className="formfield"
              variant="outlined"
              type="number"
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              fullWidth
              endAdornment={
                <InputAdornment color="white" position="end">
                  €
                </InputAdornment>
              }
            />
          </Box>

          <Box mb={3}>
            <TextField
              label={`Prix du tokens(Oka)`}
              className="formfield"
              variant="outlined"
              type="number"
              value={tokenPrice}
              onChange={(e) => {
                setTokenPrice(e.target.value);
              }}
              fullWidth
              endAdornment={
                <InputAdornment color="white" position="end">
                  €
                </InputAdornment>
              }
            />
          </Box>
          {qty > 0 && (
            <Box my={2}>
              <Typography variant="h6">
                Total: {qty} {TOKEN_NAME}s
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="flex-end" my={4}>
            <Button
              onClick={handleSubmit}
              loading={submitting}
              disabled={submitting}
              variant="contained"
              className="px-md-4 __bg-gradient"
              sx={{
                mr: 2,
              }}
            >
              {"Enregistrer"}
              {submitting ? "..." : ""}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Annuler
            </Button>
          </Box>
        </Box>
      </MUIModal>
    </div>
  );
}
