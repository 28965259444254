import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
//import Paper from "@mui/material/Paper";
import "./login.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useHistory } from "react-router-dom";
import { validateResetPasswordData } from "../../helpers/validators";
import { resetPassword } from "../../api/auth";
import { Typography } from "@mui/material";

import useQuery from "../../hooks/useQuery";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const sceneRef = useRef();

  const [userData, setUserData] = useState({
    password: "",
    passwordConfirmation: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const query = useQuery();
  const code = query.get("code");

  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const { valid, errors: validationErrors } =
        validateResetPasswordData(userData);
      if (valid) {
        setSubmitting(true);
        const { data } = await resetPassword({ ...userData, code });
        if (data) {
          setSubmitting(false);
          toast.success("Mot de passe mis à jour!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push("/auth/login");
          }, 2000);
        }
      } else {
        setErrors(validationErrors);
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <Box className="boxclass bgGradient" sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          maxWidth: "450px",
          width: "100%",
          boxShadow: "0 0 5px rgba(0,0,0,0.1)",
          py: 3
        }}
        className="bloc2 bg-white"
      >
        <Box
          sx={{
            px: 8,
            width: "100%",
            "@media (max-width: 600px)": {
              px: 2,
            },
          }}
        >
          <>
            <Typography variant="h5" textAlign="center" mb={3}>
              Réinitialiser
            </Typography>
            <TextField
              label="Mot de passe"
              type="password"
              value={userData.password}
              onChange={(e) => {
                setUserData((data) => ({
                  ...data,
                  password: e.target.value,
                }));
              }}
              helperText={errors && errors.password}
              error={errors && errors.password}
              fullWidth
            />
            <Box mt={2}>
              <TextField
                label="Confirmation de passe"
                type="password"
                value={userData.passwordConfirmation}
                onChange={(e) => {
                  setUserData((data) => ({
                    ...data,
                    passwordConfirmation: e.target.value,
                  }));
                }}
                helperText={errors && errors.passwordConfirmation}
                error={errors && errors.passwordConfirmation}
                fullWidth
              />
            </Box>
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                className="btnLog bgGradient"
                variant="contained"
                disableElevation
                onClick={handleSubmit}
                disabled={submitting}
                sx={{ textTransform: "none" }}
              >
                {submitting ? "Envoi en cours..." : "Réinitialiser"}
              </Button>
            </Box>
          </>
        </Box>
      </Box>

    </Box>
  );
}
