import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { STATUS } from "../../helpers/data";
import useAuth from "../../hooks/store/useAuth";
import { initialize, setAuthHeaders } from "../../redux/auth";
import LoadingView from "./LoadingView";

export function Initializer(props) {
    const { status } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        //analytics()
    }, [])

    let token = sessionStorage.getItem("token");
    if (token) {
        setAuthHeaders(token);
    }
    useEffect(() => {
        //setTimeout(() => {
        //// initialize redux store
        if (token) {
            dispatch(initialize());
        } /* else {
            window.location.href = "/auth/login";
        } */
        //});
    }, [dispatch, token]);

    if (token && status === STATUS.LOADING) {
        return <LoadingView />
    }
    return <>
        {props.children}
    </>
}