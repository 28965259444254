import React, { useState } from "react";
import useAuth from "../../hooks/store/useAuth";
import { toast } from "react-toastify";
import { convertToValidNumber, getDeviseFromSymbol, isStableCoin } from "../../helpers";
import { validateTransactionData } from "../../helpers/validators";
import { createTransaction } from "../../api/transactions";
import {
  Box,
  Button,
  Divider,
  Typography,
  FormControl as MuiFormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FormControl, InputGroup } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import InfoIcon from "@mui/icons-material/Info";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { TOKEN_NAME } from "../../helpers/data";
import useWallets from "../../hooks/store/useWallets";
import useRounds from "../../hooks/store/useRounds";

const Validation = ({
  handleNext,
  handleBack,
  selectedCurrency,
  amount,
  hash,
  setHash,
  qty,
  //walletId,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const { user, addTransaction } = useAuth();
  const { rounds } = useRounds();
  const round = rounds.data.find((r) => r.stock > 0);
  const tokenPrice = round?.price || 0;
  const { wallets } = useWallets();
  const wallet = wallets.data.find((w) => w.libelle === selectedCurrency.name);
  const walletId = wallet?.address;

  const onAmountCopied = () => {
    toast.success("Valeur copiée avec succès!", {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onWalledIdCopied = () => {
    toast.success("Addresse copiée avec succès!", {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const toFixed = isStableCoin(selectedCurrency.symbol) ? 8 : 2;
  const formatedPrice = parseFloat(selectedCurrency.price).toFixed(toFixed);

  const handleClick = async () => {
    try {
      const transactionData = {
        total: convertToValidNumber(qty),
        devise: getDeviseFromSymbol(selectedCurrency.symbol),
        tokenPrice: tokenPrice,
        //parrainages: [],
        devisePrice: formatedPrice,
        walletId,
        hash,
        user: user._id,
        deviceQty: amount,
        //status: TRANSACTION_STATUS.PENDING,
      };


      const validationData = {
        hash: transactionData.hash,
        currency: transactionData.devise,
        qty: transactionData.total,
        amount: transactionData.deviceQty,
      };
      const { valid, errors: validationErrors } =
        validateTransactionData(validationData);
      if (!valid) {
        setErrors(validationErrors);
      } else {
        setSubmitting(true);
        transactionData.deviceQty = convertToValidNumber(amount);
        const { data } = await createTransaction(transactionData);
        if (data.success) {
          addTransaction(data.data);
          handleNext();
          setSubmitting(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      const e = error.response?.data?.error;
      if (e) {
        console.log(e);
        if (e.status === 500) {
          toast.error("Une erreur est survenue", {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const value = `${amount} ${selectedCurrency?.name}`;
  const valueToGet = `${qty} ${TOKEN_NAME}`;

  return (
    <Box>
      <Box className="row" sx={{ mb: 40 }}>
        <Box className="col-md-4">
          <Box sx={{ p: 3 }} className="bar1">
            <Typography variant="h5" className="text-white">
              {" "}
              Vérifier et payer
            </Typography>
            <Typography className="text-white">
              Super, vous êtes sur le point d'acheter vos {TOKEN_NAME} ! Veuillez suivre
              les instructions pour confirmer votre achat et obtenir vos {TOKEN_NAME}
            </Typography>
          </Box>
        </Box>
        <Box className="col-md-8 mt-md-0 mt-4">
          <Box sx={{ p: 4, borderRadius: 2, backgroundColor: "#f4f6f8" }}>
            <Box className="row">
              <div className="col-md-4">
                <Box sx={{ mt: 1 }} className="text-center">
                  <Typography> Vous voulez investir</Typography>
                  <Typography sx={{ mt: -0.5 }}>{value}</Typography>
                </Box>
              </div>
              <div className="col-md-4">
                <Box sx={{ mt: 1 }} className="text-center">
                  <Typography> Vous obtiendrez</Typography>
                  <Typography sx={{ mt: -0.5 }}>{valueToGet}</Typography>
                </Box>
              </div>
              <div className="col-md-4">
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={handleBack}
                    sx={{ mt: 1, border: "1px solid #3f74fd", fontSize: 12 }}
                    variant="outlined"
                    disableElevation
                    size="small"
                  >
                    Modifier le montant
                  </Button>
                </div>
              </div>
            </Box>
            <Divider className="my-3" />
            <Box>
              <Typography style={{ textAlign: "left" }} >
                Afin de confirmer votre paiement de {value} et d'obtenir vos{" "}
                {valueToGet}, veuillez transférer {value} sur le portefeuille
                ci-dessous :
              </Typography>
              <div>
                <CopyToClipboard text={amount} onCopy={onAmountCopied}>
                  <Box sx={{ cursor: "pointer" }} className="mt-3">
                    <InputGroup className="mb-3">
                      <FormControl placeholder={value} value={value} readOnly />
                      <InputGroup.Text id="basic-addon2">
                        <ContentCopyIcon />
                      </InputGroup.Text>
                    </InputGroup>
                  </Box>
                </CopyToClipboard>
                <CopyToClipboard text={walletId} onCopy={onWalledIdCopied}>
                  <Box className="mt-3" sx={{ cursor: "pointer" }}>
                    <InputGroup className="mb-3">
                      <FormControl
                        placeholder={walletId}
                        value={walletId}
                        readOnly
                      />
                      <InputGroup.Text id="basic-addon2">
                        <ContentCopyIcon />{" "}
                      </InputGroup.Text>
                    </InputGroup>
                  </Box>
                </CopyToClipboard>
                <Typography color="primary">
                  N'envoyer que des {selectedCurrency.token} à cette adresse. L'envoie d'autre pièces peut entrainer une perte permanente.
                </Typography>
              </div>
              <Box mt={4}>
                <Typography>
                  <strong style={{ color: "#3f74fd" }}>
                    <InfoIcon size="small" sx={{ mt: -0.2 }} /> Important :
                  </strong>{" "}
                  Veuillez indiquer ci-dessous le hash de la transaction, sans
                  lequel votre paiement ne sera pas validé.
                </Typography>
                <MuiFormControl fullWidth sx={{ mt: 3 }}>
                  <TextField
                    id="transaction-hash"
                    value={hash}
                    onChange={(e) => setHash(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ChangeCircleIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    label="Transaction Hash"
                    focused

                    error={errors?.hash}
                    helperText={errors?.hash}
                  />
                </MuiFormControl>
              </Box>

              <Typography mt={2}>
                Veuillez cliquer sur le bouton ci-dessous qu'une fois le
                transfert effectué
              </Typography>

              <div className="mt-4">
                <Button
                  sx={{ fontSize: 12 }}
                  onClick={handleClick}
                  disabled={submitting || !walletId}
                  className="btnLogin bgGradient"
                  variant="contained"
                  disableElevation
                >
                  {submitting ? "En cours..." : "Oui, j'ai payé"}
                </Button>
              </div>
              <div className="mt-2">
                <Button
                  onClick={handleBack}

                  sx={{
                    textDecoration: "none",
                    fontSize: 12,
                    px: 8,
                    py: 1,
                    borderColor: "primary.main",
                  }}
                  variant="outlined"
                >
                  Retourner
                </Button>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Validation;
