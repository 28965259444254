import axios from "axios";
import { API_BASE_URL } from "../helpers/data";

export const addPaymentMethod = (data) => {
  return axios.post(`${API_BASE_URL}/transactions/stripe/methods`, {
    paymentMethod: data,
  });
};

export const getPaymentMethods = () => {
  return axios.get(`${API_BASE_URL}/transactions/stripe/methods`);
};

export const createPaymentIntent = (data) => {
  return axios.post(`${API_BASE_URL}/transactions/stripe/intents`, data);
};

export const confirmPaymentIntent = (data) => {
  return axios.post(
    `${API_BASE_URL}/transactions/stripe/intents/confirm`,
    data
  );
};

export const createCoinPaymentIntent = (data) => {
  return axios.post(`${API_BASE_URL}/transactions/coinpayments`, data);
};

export const getSupportedCoins = () => {
  return axios.get(`${API_BASE_URL}/transactions/coinpayments-wallets`);
};

export const getTransactionById = (id) => {
  return axios.get(`${API_BASE_URL}/transactions/transactions/${id}`);
};

export const createKKiaPayIntent = (data) => {
  return axios.post(`${API_BASE_URL}/transactions/kkiapay/intents`, data);
};

export const confirmKKiaPayIntent = (data) => {
  return axios.post(`${API_BASE_URL}/transactions/kkiapay/intents/confirm`, data);
};
