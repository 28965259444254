import React, { useCallback, useEffect, useRef, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, CircularProgress, Pagination, Typography, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
//import image from "../../images/img.png";
import { extractTransactions, getTransactions } from "../../../api/transactions";
import { STATUS, TOKEN_NAME, TRANSACTION_STATUS } from "../../../helpers/data";
import {
  formatNumber,
  formatToFrenchNumber,
  formatTransactionStatus,
} from "../../../helpers";
import moment from "moment";
import CreateForm from "./CreateForm";
import useFormValues from "../../../hooks/useFormValues";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export default function Transactions() {
  const [transactions, setTransactions] = useState({
    data: [],
  });

  const [exporting, setExporting] = useState(false);

  const { data: metadata, setFormValue, setData } = useFormValues({
    page: 1,
    search: ""
  })

  const { page, search } = metadata

  const [status, setStatus] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const params = {
          page,
          //"pagination[pageSize]": 5,
        };

        if (search.length > 0) {
          params.query = search
        }

        setStatus(STATUS.LOADING);
        const { data } = await getTransactions(params);
        if (data.success) {
          //console.log("data", data);
          setTransactions({
            ...data.data,
          });
          setStatus(STATUS.LOADED);
        }
      } catch (error) {
        console.error(error);
        setStatus(STATUS.ERROR);
      }
    };
    fetchTransactions();
  }, [page, search]);

  const handlePageChange = (event, value) => {
    //setPage(value);
    setFormValue("page", value);

  };

  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  const onSubmit = (data) => {
    const newTransactions = [data, ...transactions.data];
    setTransactions((t) => ({
      ...t,
      data: newTransactions,
    }));
  };

  const exportDataToCsv = useCallback(async (data, fileName) => {
    try {
      const EXPORT_HEADERS = [
        { label: "Nom", key: "lastName" },
        { label: "Prénom", key: "firstName" },
        { label: "Email", key: "email" },
        { label: "Wallet", key: "wallet" },
        { label: "Bonus", key: "bonus" },
        { label: "Total Tokens", key: "totalTokens" },
        { label: "Nbre de Transactions", key: "totalTxn" }

      ]

      let workbook = new ExcelJS.Workbook();
      let worksheet = workbook.addWorksheet("Ma Feuille");


      // // Définir les en-têtes
      let newValueTable = []
      EXPORT_HEADERS.forEach(({ label, key: value }, index) => {
        newValueTable.push({ header: label, key: value, width: 50 })
      })

      worksheet.columns = newValueTable;
      worksheet.getRow(1).font = { bold: true };

      const items = data//.data.filter(item => item.totalTokens > 0);

      items.forEach(item => {
        const row = {
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          bonus: item.bonus,
          totalTokens: item.totalTokens,
          totalTxn: item.totalTxn,
          wallet: item.wallet
        }

        worksheet.addRow(row);
      });

      // Appliquer les styles de bordure à toutes les lignes, y compris les données

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
        });
      });

      // Écrire dans un tampon et sauvegarder le fichier
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buffer]), `${fileName}.xlsx`);

    } catch (error) {
      console.error(error);
    } finally {
      setExporting(false)
    }
  }, [])

  const exportData = useCallback(async () => {
    try {
      setExporting(true);
      const { data } = await extractTransactions();

      if (data.success) {
        const items = data.data.filter(item => item.totalTokens > 0)
        const withWallets = items.filter(item => item.wallet);
        const withoutWallets = items.filter(item => !item.wallet);
        await exportDataToCsv(withWallets, "Utilisateurs avec wallet")
        await exportDataToCsv(withoutWallets, "Utilisateurs sans wallet")
      }

    } catch (error) {
      console.error(error);
    } finally {
      setExporting(false)
    }
  }, [exportDataToCsv])



  /*   const exportDataToCsv = useCallback(async (data) => {
      try {
  
          let workbook = new ExcelJS.Workbook();
          let allUsersWorksheet = workbook.addWorksheet('Tout les users');
          let usersWithWalletWorksheet = workbook.addWorksheet('Users avec wallet');
          let usersWithoutWalletWorksheet = workbook.addWorksheet('Users sans wallet');
  
          const allWorksheets = [allUsersWorksheet, usersWithWalletWorksheet, usersWithoutWalletWorksheet];
  
          // // Définir les en-têtes
          let newValueTable = []
          EXPORT_HEADERS.forEach(({ label, key: value }, index) => {
            newValueTable.push({ header: label, key: value, width: 50 })
          })
  
          allWorksheets.forEach((_worksheet) => {
            _worksheet.columns = newValueTable;
            _worksheet.getRow(1).font = { bold: true };
          })
  
          const { data } = await extractTransactions();
  
          if (data.success) {
            const items = data.data.filter(item => item.totalTokens > 0);
  
            items.forEach(item => {
              const row = {
                email: item.email,
                firstName: item.firstName,
                lastName: item.lastName,
                bonus: item.bonus,
                totalTokens: item.totalTokens,
                totalTxn: item.totalTxn,
                wallet: item.wallet
              }
  
              allUsersWorksheet.addRow(row);
  
              if (row.wallet) {
                usersWithWalletWorksheet.addRow(row)
              } else {
                usersWithoutWalletWorksheet.addRow(row)
              }
            });
  
            // Appliquer les styles de bordure à toutes les lignes, y compris les données
  
            allWorksheets.forEach((_worksheet) => {
              _worksheet.eachRow(function (row, rowNumber) {
                row.eachCell(function (cell, colNumber) {
                  cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                  };
                });
              });
            })
  
            // Écrire dans un tampon et sauvegarder le fichier
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), `Extractions.xlsx`);
          }
  
      } catch (error) {
        console.error(error);
      } finally {
        setExporting(false)
      }
    }, [exporting]) */

  return (
    <div className="gb">
      <div className="mx-2">
        <div
          style={{ backgroundColor: "#f4f6f8", borderRadius: "8px" }}
          className="row border-raduis p-3"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              className="col-md-4 mt-2 text-whit d-md-block d-none"
            >
              Transactions
            </Typography>
            <Box display="flex"
              justifyContent="space-between"
              alignItems="center">
              <Box marginRight={3} >
                <input
                  value={search}
                  onChange={e => {
                    setData(d => ({
                      ...d,
                      page: 1,
                      search: e.target.value
                    }))
                  }}
                  placeholder="Recherche..."
                  className="search__input"
                />
              </Box>
              <CreateForm onSubmit={onSubmit} />

              <Button
                onClick={exportData}
                loading={exporting}
                disabled={exporting}
                variant="contained"
                className="px-md-4 __bg-gradient"
                sx={{
                  ml: 2,
                }}
              >
                {"Exporter"}
                {exporting ? "..." : ""}
              </Button>
            </Box>
          </Box>

          {status === STATUS.LOADED && transactions.data.length === 0 && (
            <>
              <div
                style={{ textAlign: "center", marginTop: "20px" }}
                className="col-md-4"
              >
                <img height={150} src={"/logo-blue.svg"} alt="" />
                <br /> <br /> <br />
                <Typography className="text-whit" sx={{ fontSize: 20 }}>
                  Oh, aucune transaction trouvée.
                </Typography>
              </div>

              <div
                className="col-md-4 mt-2"
                style={{ textAlign: "right", color: "white" }}
              ></div>
            </>
          )}
        </div>
      </div>

      {transactions.data.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#fff", mt: 4 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell /* align="right" */>Utilisateur</TableCell>
                <TableCell /* align="right" */>Quantité</TableCell>
                <TableCell /* align="right" */>Prix {TOKEN_NAME}</TableCell>
                <TableCell>Méthode de paiement</TableCell>
                <TableCell /* align="right" */>Total</TableCell>
                {/* <TableCell >Hash</TableCell> */}
                <TableCell /* align="right" */>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.data.map((transaction, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {moment(transaction.createdAt).format(
                        "DD/MM/YYYY hh:mm:ss"
                      )}
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {transaction.user
                          ? `${transaction.user.firstName} ${transaction.user.lastName}`
                          : ""}
                      </Typography>{" "}
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(formatNumber(transaction.qty, 2))}
                      </Typography>{" "}
                    </TableCell>

                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(transaction.tokenPrice)}€
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography textTransform={"capitalize"}>
                        {transaction.paymentMethod}
                      </Typography>
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(
                          formatNumber(transaction.amount, 2)
                        )}
                        €
                      </Typography>{" "}
                    </TableCell>
                    {/* <TableCell >
                      <CopyToClipboard
                        text={transaction.hash}
                        onCopy={onHashCopied}
                      >
                        <Typography sx={{ cursor: "pointer" }}>
                          {formatHash(transaction.hash)}
                          <ContentCopyIcon
                            sx={{
                              fontSize: 16,
                              display: "inline-block",
                              ml: 0.2,
                            }}
                          />
                        </Typography>
                      </CopyToClipboard>
                    </TableCell> */}
                    <TableCell /* align="right" */>
                      {" "}
                      <Box
                        className="badge"
                        sx={{
                          backgroundColor:
                            transaction.status === TRANSACTION_STATUS.PENDING
                              ? "orange"
                              : transaction.status ===
                                TRANSACTION_STATUS.VALIDATED
                                ? "green"
                                : "red",
                          color: "white",
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {formatTransactionStatus(transaction.status)}{" "}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* 
        {transactions.data.length === 0 && (
          <Typography textAlign="center" m={3}>
            Aucune transaction effectuée
          </Typography>
        )} */}
        </TableContainer>
      )}
      {status === STATUS.LOADING && (
        <Box
          color="white"
          textAlign="center"
          my={3}
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {transactions.totalPages && transactions.totalPages > 1 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 3,
          }}
        >
          <Pagination
            color="primary"
            count={transactions.totalPages}
            page={page}
            onChange={handlePageChange}
            size="small"
          />
        </Box>
      ) : null}
    </div>
  );
}
