import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";

import { TOKEN_NAME, WALLET_IDS } from "../helpers/data";

import { useEffect } from "react";
import { useState } from "react";
import useCurrencies from "../hooks/store/useCurrencies";
import { useRef } from "react";

import Validation from "./Buy/Validation";
import CurrencySelection from "./Buy/CurrencySelection";
import AmountSelection from "./Buy/AmountSelection";
import Summary from "./Buy/Summary";
import useRounds from "../hooks/store/useRounds";

const COUNT_DOWN_TIME = 60 * 5;

const steps = [
  "Choisissez votre devise",
  `Combien de ${TOKEN_NAME} ?`,
  "Vérifier et payer",
  "Confirmation",
];



export default function BuySteps() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [amount, setAmount] = useState("");
  const [qty, setQty] = useState(0);
  const [walletId, setWalletId] = useState("");

  const [hash, setHash] = useState("");

  const { getCurrencies } = useCurrencies();
  const { getRounds } = useRounds();

  const timerRef = useRef();
  const countDownRef = useRef();

  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    setTimer("00:05:00");
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (countDownRef.current) clearInterval(countDownRef.current);
    const id = setInterval(() => {
      let { total, hours, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
        // update the timer
        // check if less than 10 then we need to
        // add '0' at the beginning of the variable
        setTimer(
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
        );
      }
    }, 1000);
    countDownRef.current = id;
  };

  const clearTimer = () => {
    setTimer("00:00:00");
    if (countDownRef.current) {
      clearInterval(countDownRef.current);
      countDownRef.current = null;
    }
  };

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + COUNT_DOWN_TIME);
    return deadline;
  };

  useEffect(() => {
    if (selectedCurrency) {
      //reset data after 5 minutes of waiting
      timerRef.current = setTimeout(() => {
        //alert("reset")
        setAmount("");
        setQty(0);
        setActiveStep(0);
        getCurrencies();
        clearTimer();
      }, COUNT_DOWN_TIME * 1000);
      startTimer(getDeadTime());

      return () => {
        clearTimeout(timerRef.current);
        clearTimer();
      };
    } else {
      if (timerRef.current) clearTimeout(timerRef.current);
      clearTimer();
    }
  }, [selectedCurrency, getCurrencies]);

  useEffect(() => {
    getCurrencies();
    const request = setInterval(() => {
      getCurrencies();
    }, 10000);
    return () => {
      clearInterval(request);
    };
  }, [getCurrencies]);

  useEffect(() => {
    setAmount("");
    setQty(0);
    getRounds();
    if (selectedCurrency) {
      // update wallet id
      setWalletId(WALLET_IDS[selectedCurrency.name]);
    }
  }, [selectedCurrency, getRounds]);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /*   const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }; */
  /* 
  const handleReset = () => {
    setActiveStep(0);
  };
 */
  return (
    <Box className="container" sx={{ width: "100%" }}>
      <div className="d-md-block d-none">
        <Stepper
          style={{ backgroundColor: "#fff", borderRadius: "6px" }}
          className="container text-whit p-4 mb-4 col-md-12"
          activeStep={activeStep}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProP = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.completed = false;
            }
            if (isStepSkipped(index)) {
              labelProP.completed = false;
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step
                style={{ color: "white !important" }}
                className="text-whit"
                key={label}
                {...stepProps}
              >
                <StepLabel {...labelProps}>
                  <div>{label}</div>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {countDownRef.current && (
        <Box backgroundColor="#f4f6f8" p={2} mb={2}>
          <Typography textAlign="center">
            Vous avez 5 minutes pour finir votre transaction
          </Typography>
          <Typography variant="h5" textAlign="center">
            {timer}
          </Typography>
        </Box>
      )}
      {activeStep === 0 && (
        <CurrencySelection
          handleNext={handleNext}
          setSelectedCurrency={setSelectedCurrency}
        />
      )}
      {activeStep === 1 && (
        <AmountSelection
          handleBack={() => {
            handleBack();
            setSelectedCurrency(null);
          }}
          handleNext={handleNext}
          selectedCurrency={selectedCurrency}
          amount={amount}
          setAmount={setAmount}
          qty={qty}
          setQty={setQty}
        />
      )}
      {activeStep === 2 && (
        <Validation
          handleBack={handleBack}
          handleNext={() => {
            handleNext();
            clearTimer();
          }}
          selectedCurrency={selectedCurrency}
          amount={amount}
          hash={hash}
          setHash={setHash}
          qty={qty}
          walletId={walletId}
        />
      )}
      {activeStep === 3 && (
        <Summary
          selectedCurrency={selectedCurrency}
          amount={amount}
          hash={hash}
          setHash={setHash}
          qty={qty}
          walletId={walletId}
        />
      )}
    </Box>
  );
}
