import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { getCardImage } from "../../../../helpers";
import AddPaymentModal from "../AddPaymentMethod/Index";
import { IoAdd } from "react-icons/io5";
import MUIModal from "../../../Utils/MuiModal";
import useAuth from "../../../../hooks/store/useAuth";
import moment from "moment";

export default function ListPaymentMethods({
  paymentMethods,
  onSelect,
  showActive,
  openNewPaymentMethodModal,
  isSubmitting,
  //paymentData,
}) {
  const sm = useMediaQuery("(max-width:600px)");
  const [selectedMethod, setSelectedMethod] = useState(paymentMethods[0]);

  const handleSelect = (method) => {
    setSelectedMethod(method);
    onSelect?.(method);
  };

  return (
    <Box>
      {paymentMethods.length > 0 &&
        paymentMethods.map((paymentMethod) => (
          <Box
            key={paymentMethod.id}
            sx={{
              mb: 2,
              border:
                showActive && selectedMethod?.id === paymentMethod.id
                  ? "2px solid #447A8C"
                  : "1px solid #ddd",
              borderRadius: "5px",
              padding: sm ? 1 : 2,
              cursor: "pointer",
            }}
            onClick={() => handleSelect(paymentMethod)}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={getCardImage(paymentMethod.card.brand)}
                  style={{
                    width: 40,
                  }}
                  alt=""
                />

                <Box ml={sm ? 1 : 2}>
                  <Typography fontSize="0.9rem">
                    {paymentMethod.card.brand} **** {paymentMethod.card.last4}
                  </Typography>
                  <Typography fontSize="0.9rem">
                    {paymentMethod.billing_details.name}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography fontSize="0.9rem">
                  Expires{" "}
                  {moment(
                    new Date(
                      `${paymentMethod.card.exp_year}/${paymentMethod.card.exp_month}/01`
                    )
                  ).format("MM/yyyy")}
                </Typography>
                {selectedMethod?.id === paymentMethod.id && isSubmitting && (
                  <CircularProgress size="1.5rem" sx={{ mt: 1 }} />
                )}
              </Box>
            </Box>
          </Box>
        ))}

      <AddPaymentModal
        //onSubmit={onNewPaymentMethod}
        triggerComponent={
          <Button
            variant="contained"
            className="__bg-gradient text-white"
          >
            <IoAdd
              size={20}
              style={{
                marginRight: "0.4rem",
              }}
            />{" "}
            Ajouter un moyen de paiement
          </Button>
        }
        openImediately={openNewPaymentMethodModal}
      />
    </Box>
  );
}

export const PaymentMethodsModal = ({
  isOpen,
  onClose,
  onSelect,
  qty,
  total,
  isSubmitting,
}) => {
  const { stripePaymentMethods } = useAuth();

  return (
    <MUIModal open={isOpen} handleClose={onClose}>
      <Box>
        {total && (
          <Typography variant="h6" sx={{ mb: 2 }}>
            Montant: {total} €
          </Typography>
        )}
        <Typography variant="h6" mb={2}>
          Sélectionner votre méthode de paiement
        </Typography>
        <ListPaymentMethods
          paymentMethods={stripePaymentMethods}
          onSelect={onSelect}
          showActive
          openNewPaymentMethodModal={stripePaymentMethods.length === 0}
          isSubmitting={isSubmitting}
        />
      </Box>
    </MUIModal>
  );
};
