import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const BtnWithTooltip = ({
  children,
  tip,
  btnClassName,
  tipClassName,
  onClick,
}) => (
  <>
    {tip ? (
      <Tooltip title={tip} className={tipClassName}>
        <IconButton onClick={onClick} className={btnClassName}>
          {children}
        </IconButton>
      </Tooltip>
    ) : (
      <IconButton onClick={onClick} className={btnClassName}>
        {children}
      </IconButton>
    )}
  </>
);

export default BtnWithTooltip;
