import { createSlice } from "@reduxjs/toolkit";
import { fetchCurrenciesBinance } from "../api/currencies";
import { LIFD_PRICE, STATUS } from "../helpers/data";

export const currencies = createSlice({
  name: "currencies",
  initialState: {
    data: [],
    status: "",
    tokenPrice: LIFD_PRICE,
    totalTokens: 3000000
  },
  reducers: {
    setCurrencies: (state, action) => {
      state.data = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTokenPrice: (state, action) => {
      state.tokenPrice = action.payload;
    },
    setTotalTokens: (state, action) => {
      state.totalTokens = action.payload;
    },
  },
});

export const { setCurrencies, setStatus } = currencies.actions;
export default currencies.reducer;

export const getCurrencies = () => async (dispatch, getState) => {
  const { currencies } = getState();
  if (currencies.data.length === 0) {
    dispatch(setStatus(STATUS.LOADING));
  }
  try {
    const data = await fetchCurrenciesBinance();
    //console.log("cur", data)
    dispatch(setCurrencies(data));
    dispatch(setStatus(STATUS.LOADED));
  } catch (error) {
    dispatch(setStatus(STATUS.ERROR));
  }
};

export const getTokenData = () => async (dispatch) => { };
