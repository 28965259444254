import React from "react";
import DashboardLayout from "../component/Dashboard/DashboardLayout";
import DashboardCompo from "../component/DashboardCompo";
import "./dashboard.css";
import UserVestings from "../component/Vestings/Index";
import useAuth from "../hooks/store/useAuth";
import { isValidWalletAddress } from "../helpers/validators";
import { Box } from "@mui/material"

export default function DashboardHome() {
  const { user } = useAuth();

  const showVestings = isValidWalletAddress(user.walletAddress);

  //console.log({ showVestings, W: user.walletAddress })

  return (
    <div className="bgImage">
      <DashboardLayout>
        <DashboardCompo />
        {showVestings && (
          <Box mt={3}>
            <UserVestings />
          </Box>
        )}
        {/* <Transactions /> */}
      </DashboardLayout>
    </div>
  );
}
