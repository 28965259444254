import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField } from "@mui/material";
import { getUsers } from "../../api/auth";
import { STATUS } from "../../helpers/data";

function UserSelector({ value, onChange, limit = 25 }) {
  const [users, setUsers] = useState({
    data: [],
  });
  const [query, setQuery] = useState();
  const [status, setStatus] = useState();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const params = {
          limit
        };
        if (query) {
          params.query = query
        }
        setStatus(STATUS.LOADING);
        const { data } = await getUsers(params);
        if (data.success) {
          //console.log("data", data.data);
          setUsers({
            ...data.data,
            data: data.data.data,
          });
          setStatus(STATUS.LOADED);
        }
      } catch (error) {
        console.error(error);
        setStatus(STATUS.ERROR);
      }
    };
    fetchUsers();
  }, [query, limit]);

  return (
    <FormControl variant="outlined" fullWidth>
      {/*  <InputLabel id="user-select-label">Sélection l'utilisateur</InputLabel>
      <Select
        labelId="user-select-label"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        label="User"
      >
        {users.data.map((user) => (
          <MenuItem key={user._id} value={user._id}>
            {`${user.firstName} ${user.lastName}`}
          </MenuItem>
        ))}
      </Select> */}
      <Autocomplete
        disablePortal
        id="users-autocomplete"
        options={users.data.map(u => ({ ...u, label: `${u.firstName} ${u.lastName}`, value: u._id }))}
        value={value}
        onChange={(e, v) => {
          //console.log({ v })
          if (v) {
            onChange(v._id);
          }
        }}
        fullWidth
        renderInput={(params) =>
          <TextField {...params}
            label="Rechercher un utilisateur"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
            }} />}
        sx={{ zIndex: 99 }}
      />
    </FormControl>
  );
}

export default UserSelector;
