import EuroIcon from "@mui/icons-material/Euro";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

export const CURRENCIES_API =
  "https://api.coingecko.com/api/v3/coins/markets?vs_currency=eur&order=market_cap_desc&per_page=100&page=1&sparkline=false";

export const BINANCES_CURRENCIES_API = `https://api.binance.com/api/v3/ticker/price?symbols=["BTCEUR","ETHEUR","BNBEUR","BTCUSDT","BTCUSDC","BTCDAI"]`;
//"https://api.binance.com/api/v3/ticker/price?symbols=[%22BTCEUR%22,%22ETHEUR%22,%22BNBEUR%22]"
//export const BINANCES_CURRENCIES_API = `https://api.binance.com/api/v3/ticker/price?symbols=${CURRENCIES.slice(0, 2).map(c => `"${c.symbol.toLocaleUpperCase()}EUR"`).join(",")}`

export const STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

export const CURRENCIES = [
  /*   {
        "name": "EUR",
        icon: <EuroIcon className="text-white" style={{ fontSize: "50px" }} />,
        symbol: "€", description: "",
      }, */
  {
    name: "BTC",
    icon: <EuroIcon className="text-white" style={{ fontSize: "50px" }} />,
    symbol: "BTCEUR",
    description: "",
    image: "/currencies/BTC.png",
    token: "Bitcoin (BTC)",
  },
  {
    name: "ETH",
    icon: (
      <SettingsEthernetIcon
        className="text-white"
        style={{ fontSize: "50px" }}
      />
    ),
    symbol: "ETHEUR",
    description: "",
    image: "/currencies/ETH.png",
    token: "Ethereum (ETH)",
  },
  {
    name: "BNB",
    icon: (
      <SettingsEthernetIcon
        className="text-white"
        style={{ fontSize: "50px" }}
      />
    ),
    symbol: "BNBEUR",
    description: "",
    image: "/currencies/BNB.png",
    token: "BNB (BNB)",
  },
  {
    name: "USDT",
    icon: (
      <MonetizationOnIcon className="text-white" style={{ fontSize: "50px" }} />
    ),
    symbol: "USDTEUR",
    description: "",
    image: "/currencies/USDT.png",
    token: "Tether (ERC20)",
  },
  {
    name: "USDC",
    icon: (
      <MonetizationOnIcon className="text-white" style={{ fontSize: "50px" }} />
    ),
    symbol: "USDCEUR",
    description: "",
    image: "/currencies/USDC.png",
    token: "USD Coin (ERC20)",
  },
  {
    name: "DAI",
    icon: <EuroIcon className="text-white" style={{ fontSize: "50px" }} />,
    symbol: "DAIEUR",
    description: "",
    image: "/currencies/DAI.png",
    token: "Dai (ERC20)",
  },
];

export const WALLET_IDS = {
  BTC: "BTC_WALLET_ID",
  ETH: "ETH_WALLET_ID",
  BNB: "BNB_WALLET_ID",
  USDT: "USDT_WALLET_ID",
  USDC: "USDC_WALLET_ID",
  DAI: "DAI_WALLET_ID",
};

export const LIFD_PRICE = 1;
export const TOKEN_NAME = "OkaCoin";
export const PROJECT_NAME = "OKAIWA";

export const USER_ROLE = {
  ADMIN: "admin",
  AUTHENTICATED: "authenticated",
};

export const TRANSACTION_STATUS = {
  PENDING: "pending",
  VALIDATED: "approved",
  REJECTED: "rejected",
};

export const PAYMENT_METHODS = {
  CREDIT_CARD: "CREDIT_CARD",
  CRYPTO: "CRYPTO",
  MOBILE_MONEY: "MOBILE_MONEY"
};

export const ALLOWED_COINS = [
  "BTC",
  "BNB.BSC",
  "DAI",
  "DAI.BEP20",
  "ETH",
  "USDC",
  "USDC.BEP20",
  "USDC.TRC20",
  "USDT.ERC20",
  "USDT.TRC20",
  /*  "LTCT" */
];
