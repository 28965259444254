import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
//import Paper from "@mui/material/Paper";
import "./login.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { isEmail } from "../../helpers/validators";
import { sendResetPasswordLink } from "../../api/auth";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Logo } from "../../component/Dashboard/NavItem";

export default function ForgotPassword() {
  const sceneRef = useRef();

  const [userData, setUserData] = useState({
    email: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  const handleSubmit = async () => {
    try {
      if (isEmail(userData.email)) {
        setSubmitting(true);
        const { data } = await sendResetPasswordLink(userData);
        if (data) {
          setSubmitting(false);
          setSent(true);
        }
      } else {
        setErrors({ email: "Email invalide" });
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);
      if (error.response.data?.message) {
        setErrors({ email: error.response.data.message });
      }
    }
  };

  return (
    <Box className="boxclass bgGradient" sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box>
        <div className="d-flex justify-content-center">
          <Logo fontSize="1.5rem" />
        </div>
        <Box
          sx={{
            mt: 2,
            maxWidth: "400px",
            width: "100%",
            boxShadow: "0 0 5px rgba(0,0,0,0.1)",
            py: 2,
            "@media screen and (min-width: 600px)": {
              width: "450px",
            }
          }}
          className="bloc2 bg-white rounded"
        >
          <Box
            sx={{
              px: 8,
              width: "100%",
              "@media (max-width: 600px)": {
                px: 2,
              },
            }}
          >
            {sent ? (
              <Box>
                <Box
                  className="bgGradient"
                  px={2}
                  py={3}
                  sx={{ borderRadius: 3, width: "100%" }}
                >
                  <Typography color="white" variant="h6" textAlign="center">
                    Un lien de réinitialisation a été envoyé à votre email
                  </Typography>
                </Box>
                <Typography
                  variant="h6"
                  component={Link}
                  to="/auth/login"
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    display: "block",
                    textDecoration: "underline",
                  }}
                >
                  Retour
                </Typography>
              </Box>
            ) : (
              <>
                <Typography
                  variant="h5"
                  textAlign="center"
                  mb={3}
                >
                  Réinitialiser
                </Typography>

                <TextField
                  label="Email"
                  value={userData.email}
                  onChange={(e) => {
                    setUserData((data) => ({ ...data, email: e.target.value }));
                  }}
                  helperText={errors && errors.email}
                  error={errors && errors.email}
                  fullWidth
                />
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    className="btnLog bgGradient"
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}
                    disabled={submitting}
                    sx={{ textTransform: "none" }}
                  >
                    {submitting ? "Envoi en cours..." : "Envoyer le lien"}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

    </Box>
  );
}
