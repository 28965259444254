import {
  Box,
  Button,
  FormControl,
  //InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { createWallet, updateWallet } from "../../../api/wallets";
import { validateWalletData } from "../../../helpers/validators";
import MUIModal from "../../Utils/MuiModal";
import { toast } from "react-toastify";
import useWallets from "../../../hooks/store/useWallets";
import { CURRENCIES } from "../../../helpers/data";

export default function WalletForm({ trigerComponent, wallet, onSubmit }) {
  const [open, setOpen] = useState(false);
  const [walletData, setWalletData] = useState({
    libelle: "",
    address: "",
  });
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { wallets, addOrUpdateWallet } = useWallets();

  let REMAINING_WALLETS = CURRENCIES.filter(
    (c) => !wallets.data.find((w) => w.libelle === c.name)
  );

  if (wallet) {
    const currency = CURRENCIES.find((c) => c.name === wallet.libelle);
    REMAINING_WALLETS.unshift(currency);
  }

  useEffect(() => {
    if (wallet && open) {
      setWalletData({
        libelle: wallet.libelle,
        address: wallet.address,
      });
    }
  }, [wallet, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors(null);
    setWalletData({
      libelle: "",
      address: "",
    });
  };

  const handleSubmit = async () => {
    const { valid, errors: validationErrors } = validateWalletData(walletData);

    if (!valid) {
      setErrors(validationErrors);
    } else {
      try {
        //if (!wallet) {
        setSubmitting(true);
        const request = wallet ? updateWallet : createWallet;
        const { data } = await request({ ...wallet, ...walletData });
        if (data.success) {
          addOrUpdateWallet(data.data);
          toast.success(
            wallet
              ? "Adresse modifiée avec succès!"
              : "Adresse créée avec succès!",
            {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        setSubmitting(false);
        handleClose();
        //}
      } catch (error) {
        console.log(error);
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Box onClick={handleOpen}>{trigerComponent}</Box>

      <MUIModal open={open} handleClose={handleClose}>
        <Box>
          <Typography variant="h6" color="#222" mb={2}>
            Wallet {wallet?.libelle}
          </Typography>

          <FormControl
            fullWidth
          >
            <InputLabel id="currencies-select-label">
              Devise
            </InputLabel>
            <Select
              labelId="currencies-select-label"
              id="currencies-select"
              value={walletData.libelle}
              label="Age"
              onChange={(e) => {
                setWalletData({
                  ...walletData,
                  libelle: e.target.value,
                });
              }}
            >
              {REMAINING_WALLETS.map((w) => (
                <MenuItem key={w.name} value={w.name}>
                  {w.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box my={3}>
            <TextField
              label="Adresse"
              className="formfield"
              variant="outlined"
              value={walletData.address}
              onChange={(e) => {
                setWalletData({ ...walletData, address: e.target.value });
              }}
              helperText={errors?.address}
              error={errors?.address}
              fullWidth
            />
          </Box>
          <Button
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
            variant="contained"
            className="px-md-4 bgGradient"
            sx={{
              mr: 2,
            }}
          >
            {submitting ? "En cours..." : wallet ? "Modifier" : "Valider"}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
        </Box>
      </MUIModal>
    </>
  );
}
