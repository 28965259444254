import { createSlice } from "@reduxjs/toolkit";
/* import axios from "axios";
import { getCurrentUser } from "../api/auth";
import { getUserPreferences } from "../api/user";
import {
  getUserBasicDetails,
  removeSecureData,
  removeStorageData,
  setStorageData,
} from "../helpers"; */
import { STATUS } from "../helpers/data";
import axios from "axios";
import { getAuthUser } from "../api/auth";
import { getWallets } from "./wallets";
import { getRoundActive, getRounds } from "./rounds";
import { getUserBalance } from "../api/transactions";
import { getPaymentMethods } from "../api/payments";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null,
    status: STATUS.LOADING,
    balance: 0,
    stripePaymentMethods: [],
  },
  reducers: {
    setAuthenticated(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setUnauthenticated(state, action) {
      state.isAuthenticated = false;
      state.user = null;
    },
    setAuthStatus(state, action) {
      state.status = action.payload;
    },
    setAuthUser(state, action) {
      state.user = action.payload;
    },
    setUserPreferences(state, action) {
      console.log(action.payload);
      state.userPreferences = action.payload;
    },
    addUserTransaction(state, action) {
      state.user.transactions = [action.payload, ...state.user.transactions];
    },
    setUserBalance(state, action) {
      state.balance = action.payload;
    },
    setStripePaymentMethods(state, action) {
      state.stripePaymentMethods = action.payload;
    },
  },
});

export const {
  setAuthenticated,
  setAuthUser,
  setAuthStatus,
  setUnauthenticated,
  setUserPreferences,
  addUserTransaction,
  setUserBalance,
  setStripePaymentMethods,
} = authSlice.actions;
export default authSlice.reducer;

export const initialize = () => async (dispatch) => {
  try {
    dispatch(setAuthStatus(STATUS.LOADING));
    const { data } = await getAuthUser();
    if (data.success) {
      dispatch(setAuthenticated(data.data));
      dispatch(setAuthStatus(STATUS.LOADED));
      await dispatch(getWallets());
      await dispatch(getRounds());
      await dispatch(getRoundActive());
      await dispatch(fetchUserBalance());
      await dispatch(fetchUserPaymentMethods());
    }
  } catch (error) {
    console.error(error);
    dispatch(setAuthStatus(STATUS.ERROR));
  }
};

export const refreshUserData = () => async (dispatch) => {
  try {
    const { data } = await getAuthUser();
    if (data.success) {
      dispatch(setAuthenticated(data.data));
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserPaymentMethods = () => async (dispatch) => {
  try {
    const { data } = await getPaymentMethods();
    if (data.success) {
      //console.log("PM ==>", data.data.data);
      dispatch(setStripePaymentMethods(data.data.data));
      //dispatch(setUserBalance(data.data?.total));
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchUserBalance = () => async (dispatch) => {
  try {
    const { data } = await getUserBalance();
    if (data.success) {
      dispatch(setUserBalance(data.data?.total));
    }
  } catch (error) {
    console.error(error);
  }
};

export const logoutUser = () => async (dispatch) => {
  dispatch(setUnauthenticated());
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  delete axios.defaults.headers.common["Authorization"];
};

export const setAuthHeaders = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const setAuthSessionData = (user, token) => {
  sessionStorage.setItem("user", JSON.stringify(user));
  if (token) {
    sessionStorage.setItem("token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};
