import axios from "axios";
import { API_BASE_URL } from "../helpers/data";

export const getWallets = () => {
    return axios.get(`${API_BASE_URL}/wallets`);
};

export const createWallet = (data) => {
    return axios.post(`${API_BASE_URL}/wallets`, data);
};

export const updateWallet = (data) => {
    return axios.put(`${API_BASE_URL}/wallets/${data._id}`, data);
};

export const deleteWallet = (id) => {
    return axios.delete(`${API_BASE_URL}/wallets/${id}`);
};
