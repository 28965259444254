import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWallets as getWalletsAction, addWallet as addWalletAction, updateWallet as updateWalletAction, addOrUpdateWallet as addOrUpdateWalletAction, deleteWallet as deleteWalletAction } from "../../redux/wallets"

const useWallets = () => {
    const { wallets, status } = useSelector(state => state.wallets);

    const dispatch = useDispatch();

    const getWallets = useCallback(() => {
        dispatch(getWalletsAction());
    }, [dispatch])

    const addOrUpdateWallet = useCallback((wallet) => {
        dispatch(addOrUpdateWalletAction(wallet));
    }
        , [dispatch])

    const addWallet = useCallback((wallet) => {
        dispatch(addWalletAction(wallet));
    }
        , [dispatch])

    const updateWallet = data => {
        dispatch(updateWalletAction(data));
    }

    const deleteWallet = data => {
        dispatch(deleteWalletAction(data));
    }

    return {
        wallets,
        status,
        getWallets,
        addOrUpdateWallet,
        addWallet,
        updateWallet,
        deleteWallet
    }
}

export default useWallets 