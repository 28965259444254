import React from 'react';
import useAuth from '../../hooks/store/useAuth';
import UserWalletForm from '../Admin/Users/UserWalletForm';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, Typography } from '@mui/material';

export default function EditWallet() {
    const { user, setUser } = useAuth();

    const onUpdate = (data) => {
        //console.log({ data })
        setUser({
            ...user,
            ...data,
        })
    }
    return (
        <UserWalletForm
            wallet={user.walletAddress}
            onUpdate={onUpdate}
            triggerComponent={
                <Box display="flex" alignItems="center"
                    sx={{
                        mx: 1,
                        px: 2,
                        py: 1,
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        "&:hover": {
                            opacity: 0.85,
                        },
                    }}>
                    <AccountBalanceWalletIcon
                        sx={{ color: "#fff" }}
                    />
                    <Typography color="white" ml={1.5} className="text-poppins">
                        Wallet
                    </Typography>
                </Box>
            }
        />
    );
}
