import React from "react";
import DashboardLayout from "../component/Dashboard/DashboardLayout";
import "./dashboard.css";
import UserVestings from "../component/Vestings/Index";
import { WalletConnectProvider } from "../component/Utils/WalletConnectProvider.jsx";

export default function Vestings() {
  return (
    <div className="bgImage">
      <>
        <DashboardLayout>
          <UserVestings />
        </DashboardLayout>
      </>
    </div>
  );
}
