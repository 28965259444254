import { Alert, Box, Button, Paper, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { TOKEN_NAME } from "../../helpers/data";
/* import { initialize } from "../../redux/auth";
import { useDispatch } from "react-redux"; */

const Summary = ({ selectedCurrency, amount, hash, qty, walletId }) => {

    const history = useHistory();
    //const dispatch = useDispatch();

    const handleNext = () => {
        //dispatch(initialize())
        history.push("/transactions");
    }

    return (
        <Box>
            <div
                style={{ backgroundColor: "#fff" }}
                className="col-md-12 d-flex"
            ></div>

            <Box /*  sx={{ maxWidth: 600, mx: "auto" }} */>
                <Paper sx={{ p: 3, backgroundColor: "#f4f6f8", borderRadius: 2 }}>
                    <Alert severity="success">
                        Transaction enrégistrée
                    </Alert>
                    <Typography mt={2} mb={1}>
                        Votre transaction a bien été enrégistrée et est en attente de validation
                    </Typography>
                    <Box>
                        <Typography mb={0.5}>
                            Quantité de {TOKEN_NAME}: {qty}
                        </Typography>
                        <Typography mb={0.5}>
                            Pour: {amount} {selectedCurrency.name}
                        </Typography>
                        <Typography mb={0.5}>
                            Prix du {selectedCurrency.name}: {selectedCurrency.price}€
                        </Typography>
                        <Typography mb={0.5}>
                            ID du portefeuille: {walletId}
                        </Typography>
                        <Typography>
                            Hash: {hash}
                        </Typography>
                    </Box>
                </Paper>

                <Box display="flex" justifyContent="flex-end" mt={3} sx={{
                    "@media (max-width: 600px)": {
                        justifyContent: "center"
                    }
                }}>
                    <Button
                        sx={{ textDecoration: "none", fontSize: 12, px: 8, py: 1 }}
                        className="btnLogin bgGradient"
                        variant="contained"
                        disableElevation
                        onClick={handleNext}
                    >
                        Continuer
                    </Button>
                </Box>
            </Box>
        </Box >
    );
};

export default Summary;