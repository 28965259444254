import React, { useState, useEffect } from "react";
import { ProtectedByRole } from "../../component/Utils/ProtectedByRole";
import { STATUS, USER_ROLE } from "../../helpers/data";
import DashboardLayout from "../../component/Dashboard/DashboardLayout";
import { getUsers } from "../../api/auth";
import { Box, Typography } from "@mui/material";
import UsersLists from "../../component/Admin/Users/UsersLists";
//import { spreadAttributes } from "../../helpers";
import UserTransactions from "../../component/Admin/Users/UserTransactions";

export default function Users() {
  const [users, setUsers] = useState({
    data: [],
  });

  const [filter, setFilter] = useState({
    query: "",
    page: 1
  });
  const [status, setStatus] = useState("");

  const [selectedUser, setSelectedUser] = useState(null);

  const { page, query } = filter

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { page, query } = filter
        const params = {
          page
        };
        if (query) {
          params.query = query;
        }
        setStatus(STATUS.LOADING);
        const { data } = await getUsers(params);
        if (data.success) {
          //console.log("data", data.data);
          setUsers({
            ...data.data,
            data: data.data.data,
          });
          setStatus(STATUS.LOADED);
        }
      } catch (error) {
        console.error(error);
        setStatus(STATUS.ERROR);
      }
    };
    fetchUsers();
  }, [filter]);

  const handlePageChange = (event, value) => {
    setFilter(d => ({
      ...d,
      page: value
    }));
  };

  const onUpdate = (data) => {
    const newUsers = users.data.map((transaction) => {
      if (transaction._id === data._id) {
        return {
          ...transaction,
          status: data.status,
        };
      }
      return transaction;
    });
    setUsers({
      ...users,
      data: newUsers,
    });
  };

  const onTransactionUpdate = (data) => {
    const newUsers = users.data.map((user) => {
      if (user._id === selectedUser._id) {
        return {
          ...user,
          transactions: user.transactions.map((transaction) => {
            if (transaction._id === data._id) {
              return {
                ...transaction,
                status: data.status,
              };
            }
            return transaction;
          }),
        };
      }
      return user;
    });
    setUsers({
      ...users,
      data: newUsers,
    });
    setSelectedUser((user) => ({
      ...user,
      transactions: user.transactions.map((transaction) => {
        if (transaction._id === data._id) {
          return {
            ...transaction,
            status: data.status,
          };
        }
        return transaction;
      }),
    }));
  };

  return (
    <ProtectedByRole
      role={USER_ROLE.ADMIN}
      onRedirect={(role) => {
        return `/auth/login`;
      }}
    >
      <DashboardLayout>
        <Box>
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", backgroundColor: "#f4f6f8", px: 3, py: 1.5, borderRadius: 2 }}
          >
            <Typography
              variant="h5"
              gutterBottom
              className="mt-2"
            >
              Utilisateurs
            </Typography>

            <Box >
              <input
                value={query}
                onChange={e => {
                  setFilter(f => ({
                    ...f,
                    page: 1,
                    query: e.target.value
                  }))
                }}
                placeholder="Recherche..."
                className="search__input"
              />
            </Box>
          </Box>
          <Box>
            {selectedUser ? (
              <UserTransactions
                user={selectedUser}
                setUser={setSelectedUser}
                onTransactionUpdate={onTransactionUpdate}
              />
            ) : (
              <UsersLists
                users={users}
                status={status}
                page={page}
                handlePageChange={handlePageChange}
                onUpdate={onUpdate}
                setSelectedUser={setSelectedUser}
              />
            )}
          </Box>
        </Box>
      </DashboardLayout>
    </ProtectedByRole>
  );
}
