import { useState } from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SwapVerticalIcon from "@mui/icons-material/SwapVert";
import { FormControl, InputGroup } from "react-bootstrap";
import { convertToValidNumber, formatNumber, isFloat, isStableCoin } from "../../helpers";
import {
  isValidNumber,
  validateAmountSelectionData,
} from "../../helpers/validators";
import useCurrencies from "../../hooks/store/useCurrencies";
import useRounds from "../../hooks/store/useRounds";
import { TOKEN_NAME } from "../../helpers/data";

const AmountSelection = ({
  handleNext,
  handleBack,
  selectedCurrency,
  amount,
  setAmount,
  qty,
  setQty,
}) => {
  const [loadingAmount, setLoadingAmount] = useState(false);
  const [loadingQty, setLoadingQty] = useState(false);
  const [errors, setErrors] = useState(null);

  const { currencies } = useCurrencies();
  const { rounds, getRounds } = useRounds();
  const round = rounds.data.find((r) => r.stock > 0);
  const tokenPrice = round?.price || 0;

  const md = useMediaQuery("(min-width:768px)");

  const handleClick = () => {
    if (amount && qty && round) {
      const { valid, errors: validationErrors } = validateAmountSelectionData({
        qty,
        totalQuantity: round.stock,
      });
      if (valid) {
        handleNext();
      } else {
        getRounds()
        console.log("validationErrors", validationErrors)
        setErrors(validationErrors);
      }
      /* if (qty > round.stock) {
        setQty(round.stock.toString());
      }
      handleNext(); */
    }
  };

  const getQty = (amount) => {
    //console.log("amount", amount)
    const currency = currencies.find(
      (c) => c.symbol === selectedCurrency.symbol
    );
    const convertedPrice = currency.price * amount;
    //console.log("convertedPrice", convertedPrice)
    const toFixed = isStableCoin(selectedCurrency.symbol) ? 8 : 2;
    let value = convertedPrice / tokenPrice;
    value = isFloat(value) ? formatNumber(value, toFixed) : value.toString();
    return value;
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);

    if (isValidNumber(value)) {
      setLoadingQty(true);
      let result = getQty(value);
      //console.log("__result", result)
      result = parseInt(result, 10);
      //console.log("result", result)
      setQty(result.toString() /* .toFixed(3) */);
      setLoadingQty(false);
    } else {
      setQty("");
    }
  };

  const getAmount = (qty) => {
    //console.log("qty", qty)
    const currency = currencies.find(
      (c) => c.symbol === selectedCurrency.symbol
    );
    const price = qty * tokenPrice;
    //console.log("price", price)
    const toFixed = isStableCoin(selectedCurrency.symbol) ? 8 : 2;
    const convertedPrice = formatNumber(price / currency.price, toFixed);
    //console.log("convertedPrice", convertedPrice)
    return convertedPrice;
  };

  const handleQtyChange = (e) => {
    let value = e.target.value;
    setQty(value);

    if (isValidNumber(convertToValidNumber(value))) {
      setLoadingAmount(true);
      const result = getAmount(convertToValidNumber(value));
      setAmount(result /* .toFixed(3) */);
      setLoadingAmount(false);
    } else {
      setAmount("");
    }
  };

  console.log("AMOUNT", amount, convertToValidNumber(amount))

  return (
    <Box>
      <Box className="row">
        <Box className="col-md-5">
          <Box sx={{ p: 4 }} className="bar1">
            <Typography variant="h6" className="text-white">
              Combien de {TOKEN_NAME} voulez-vous acheter ?
            </Typography>
            <Typography className="text-white">
              Vous pouvez saisir soit la quantité que vous souhaitez acheter,
              soit la quantié de {TOKEN_NAME} que vous souhaitez acheter.
            </Typography>
          </Box>
        </Box>
        <Box className="col-md-7 mt-md-0 mt-4">
          <Box sx={{ p: 4, borderRadius: 2, backgroundColor: "#f4f6f8" }}>
            <Box className="row align-items-center">
              <Box className="col-md-5">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="500"
                    aria-label="500"
                    aria-describedby="basic-addon2"
                    value={amount}
                    //onKeyDown={handleAmountChange}
                    onChange={handleAmountChange}
                    disabled={loadingAmount}
                    autoFocus
                  />
                  <InputGroup.Text id="basic-addon2">
                    {selectedCurrency?.name}
                  </InputGroup.Text>
                </InputGroup>
              </Box>
              <Box sx={{ mt: -2 }} className="col-md-1">
                {md ? (
                  <SwapHorizIcon
                    sx={{ fontSize: 40, ml: -0.7 }}
                    className="text-whit"
                  />
                ) : (
                  <Box display="flex" justifyContent="center">
                    <SwapVerticalIcon
                      sx={{ fontSize: 40, my: 1, mx: "auto" }}
                      className="text-center"
                    />
                  </Box>
                )}
              </Box>
              <Box className="col-md-6">
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="1300"
                    aria-label="1300"
                    aria-describedby="basic-addon2"
                    value={qty}
                    //onKeyDown={handleQtyChange}
                    onChange={handleQtyChange}
                    disabled={loadingQty}
                  />
                  <InputGroup.Text id="basic-addon2">{TOKEN_NAME}</InputGroup.Text>
                </InputGroup>
                {errors?.qty && (
                  <Typography variant="body2" mt={-1} className="text-danger">
                    {errors.qty}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", mt: 4 }}>
              <Button
                onClick={handleClick}
                className="btnLogin bgGradient"
                variant="contained"
                disableElevation
              >
                Confirmer
              </Button>
              <Box sx={{ mt: 1 }}>
                <Button
                  onClick={handleBack}
                  className="link1"
                  style={{ textDecoration: "none" }}
                >
                  Retourner
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AmountSelection;
