import React from "react";
import DashboardLayout from "../component/Dashboard/DashboardLayout";
import Buy from "../component/Buy";
import "./dashboard.css";

export default function PurchaseCoins() {
  return (
    <div className="bgImage">
      <DashboardLayout>
        <Buy />

      </DashboardLayout>
    </div>
  );
}
