import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MUIModal from "../../Utils/MuiModal";
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
//import useAuth from '../../../hooks/store/useAuth';
import { updateUser } from '../../../api/auth';
import useAuth from '../../../hooks/store/useAuth';
import { isValidWalletAddress } from "../../../helpers/validators"
import {
  useAccount,
  useSwitchChain,
  useDisconnect
} from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import ProfileIcon from "@mui/icons-material/Wallet"
import LogoutIcon from "@mui/icons-material/Logout";
import BtnWithTooltip from '../../Utils/BtnWithTooltip';



export default function UserWalletForm({ wallet: userWallet, onUpdate, triggerComponent }) {
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const { user, balance } = useAuth()

  const account = useAccount();
  const chain = account.chain;
  const { switchChain } = useSwitchChain();
  const web3Modal = useWeb3Modal();
  const { disconnect } = useDisconnect()

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);


  const walletAddressChanged = useMemo(() => {
    return account.address ? user.walletAddress !== account.address : false
  }, [user, account])

  const disabledWalletInput = useMemo(() => {
    if (balance > 0 && isValidWalletAddress(user.walletAddress)) {
      return true
    } else {
      return false
    }
  }, [user, balance])

  /*   useEffect(() => {
      if(isValidWalletAddress(user.walletAddress) && balance > 0) {
        //cannot update wallet address
      } else {
        
      }
    }, [balance, user]) */

  useEffect(() => {
    console.log({ account })

    if (account?.address && account.address !== user.walletAddress) {
      //address has been changed
      handleOpen()
    }
  }, [account, user, handleOpen])

  useEffect(() => {
    if (userWallet) {
      setWallet(userWallet);
    } else {
      setOpen(true)
    }
  }, [userWallet]);

  useEffect(() => {
    if (chain !== process.env.REACT_APP_VESTING_CHAIN_ID) {
      switchChain?.(
        { chainId: parseInt(process.env.REACT_APP_VESTING_CHAIN_ID ?? "0") }
      );
    }
  }, [chain]);


  const connectWallet = useCallback(() => {
    web3Modal.open()
    handleClose()
  }, [web3Modal])

  const handleSubmit = useCallback(async () => {
    if (account.address) {
      setSubmitting(true);
      const { data } = await updateUser({
        walletAddress: account.address,
      })
      if (data) {
        //console.log("data", data)
        onUpdate(data.data);
        setSubmitting(false);
        handleClose();
      }
    }
  }, [account.address, onUpdate])

  return (
    <>
      <Box onClick={handleOpen}>{triggerComponent}</Box>
      <MUIModal open={open} handleClose={handleClose}>
        <Box>
          <Typography variant="h6" mb={0.5}>
            Veuillez entrer l'adresse de votre wallet
          </Typography>
          <Typography variant="body2" mb={0.5}>
            (Entrez l'adresse de votre wallet <strong>ERC20</strong> décentralisé pour recevoir votre <strong>Drop</strong>)
          </Typography>
          <Typography variant="h6" mb={2} color="red" fontSize={13}>
            ATTENTION ! Aucun portefeuille d'échange ne doit être utilisé sous peine de perdre vos tokens définitivement
          </Typography>
          <Box mb={2}>
            {account.isConnected ?
              (<Box className="poppins" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", my: 1, backgroundColor: "#F5F5F5", p: 1 }}>
                <Box className="poppins" sx={{ display: "flex", alignItems: "center" }}>
                  <ProfileIcon />
                  <Typography variant="h6" fontSize={14} ml={1}>{account.address}</Typography>
                </Box>

                {!disabledWalletInput &&

                  <Box ml={4}>
                    <BtnWithTooltip onClick={disconnect} tip={"Se déconnecter"}>
                      <LogoutIcon color="error" style={{ marginRight: "10px" }} />
                    </BtnWithTooltip>
                  </Box>
                }
              </Box>) : (
                isValidWalletAddress(user.walletAddress) && (
                  <Box className="poppins" sx={{ display: "flex", alignItems: "center" }}>
                    <ProfileIcon />
                    <Typography variant="h6" fontSize={14} ml={1}>{user.walletAddress}</Typography>
                  </Box>
                )
              )}
          </Box>
          {!disabledWalletInput && (
            <Box display="flex" justifyContent="center">
              {!account.isConnected && <Button
                onClick={connectWallet}
                className="mb-4 text-white bgGradient"
                sx={{ px: 8 }}
              >
                <ProfileIcon sx={{ mr: 1 }} />
                Wallet Connect
              </Button>}
            </Box>
          )}
          {walletAddressChanged && (
            <Box>
              <Button
                onClick={handleSubmit}
                loading={submitting}
                disabled={submitting}
                variant="contained"
                className="px-md-4 bgGradient"
                sx={{
                  mr: 2,
                }}
              >
                {submitting ? "En cours..." : "Sauvegarder" /* userWallet ? "Modifier" : "Valider" */}
                {submitting && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      ml: 1
                    }}
                  />
                )}
              </Button>

              <Button variant="outlined" onClick={handleClose}>
                Annuler
              </Button>

            </Box>
          )}
        </Box>
      </MUIModal>
    </>
  )
}
