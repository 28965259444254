import React from "react";
import { ProtectedByRole } from "../../component/Utils/ProtectedByRole";
import { USER_ROLE } from "../../helpers/data";
import DashboardLayout from "../../component/Dashboard/DashboardLayout";
import Transactions from "../../component/Admin/Transactions/Transactions";

export default function TransactionsAdmin() {
  return (
    <ProtectedByRole
      role={USER_ROLE.ADMIN}
      onRedirect={(role) => {
        return `/auth/login`;
      }}
    >
      <DashboardLayout>
        <Transactions />
      </DashboardLayout>
    </ProtectedByRole>
  );
}
