import React from "react";

//import config from "../utils/config.json";
//import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "../../../helpers/stripe";

//const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);

export default function StripeWrapper({ clientSecret, children }) {
  const stripe = getStripe();
  const options = {
    clientSecret: clientSecret,
  };

  return (
    <Elements stripe={stripe} options={options}>
      {children}
    </Elements>
  );
}
