import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { ALLOWED_COINS, STATUS } from "../../../helpers/data";
//import { eventSocketTransaction } from "../../../helpers/events";
//import useSocketIoListener from "../../../hooks/useSocketIoListener";
import {
  createCoinPaymentIntent,
  getSupportedCoins,
} from "../../../api/payments";
import { BiChevronRight, BiChevronLeft, BiSearch } from "react-icons/bi";
import CopyWrapper from "../../Utils/CopyWrapper";
import MUIModal from "../../Utils/MuiModal";
import { useHistory } from "react-router-dom";
import useRounds from "../../../hooks/store/useRounds";
import useSocketIoListener from "../../../hooks/useSocketIoListener";
import { transactionUpdated } from "../../../helpers/events";
import { toast } from "react-toastify";


const CoinPaymentModal = ({ isOpen, onClose, onBack, qty, total, roundId }) => {
  const [coins, setCoins] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState();
  const [searchText, setSearchText] = useState("");
  const [coinpaymentsData, setCoinpaymentsData] = useState();
  const [showPendingView, setShowPendingView] = useState(false);
  const [status, setStatus] = useState("LOADING_COINS");
  const isLoading = status === STATUS.LOADING;


  const router = useHistory();

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const { data } = await getSupportedCoins();
        if (data.success) {
          //setCoins(data.data);
          setAllCoins(data.data);
          setStatus("LOADED_COINS");
        }
      } catch (err) {
        console.error(err);
        setStatus("LOADED_COINS_FAILED");
      }
    };
    fetchCoins();
  }, []);

  useEffect(() => {
    const results = allCoins.filter((c) => {
      const pattern = new RegExp(searchText, "i");
      return pattern.test(c.name);
    });
    setCoins(results);
  }, [searchText, allCoins]);

  const handleSubmit = useCallback(async () => {
    try {
      if (roundId) {
        setStatus(STATUS.LOADING);
        const submitData = {
          amount: total,
          roundId,
          qty,
          currency: selectedCoin.key,
        };
        const { data } = await createCoinPaymentIntent(submitData);
        if (data.success) {
          setCoinpaymentsData(data.data);
          setStatus(STATUS.LOADED);
          //validateTransaction(data.data.transactionDocId);
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response?.data?.message) {
        toast.error(error.response.data.message, {
          position: "bottom-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setStatus(STATUS.ERROR);
    }
  }, [total, qty, roundId, selectedCoin]);

  /*   const validateTransaction = (transactionDocId) => {
        setTimeout(() => {
          validatePayment(transactionDocId);
        }, 15000);
      }; */

  useSocketIoListener(transactionUpdated, (transaction) => {
    //console.log({ transaction });
    if (transaction) {
      if (transaction.paymentMethod === "coinpayments") {
        if (transaction.status === "approved") {
          toast.success("Paiement effectué avec succès!", {
            position: "bottom-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          router.push(`/dashboard`);
        } else if (transaction.status === "pending") {
        }
      }
    }
  });

  //console.log({ coins });

  const filteredCoins = coins//.filter(c => ALLOWED_COINS.includes(c.key))

  return (
    <MUIModal open={isOpen} handleClose={onClose} maxWidth="xs">
      <Box>
        {!coinpaymentsData ? (
          <>
            <Box display="flex" alignItems="center" mb={0.7}>
              {onBack && (
                <BiChevronLeft
                  size={32}
                  color="#777"
                  className="pointer"
                  onClick={() => {
                    onBack();
                  }}
                />
              )}
              <Typography ml={2} textAlign="center" variant="h6">
                {"Sélectionnez une crypto"}
              </Typography>
            </Box>
            <Box
              sx={{
                ml: 1,
                mb: 1,
                mr: 2,
                position: "relative",
                "& #search-icon": {
                  position: "absolute",
                  top: 7,
                  left: 8,
                },
                "& input": {
                  border: "1px solid #eee",
                  backgroundColor: "#fff",
                  borderRadius: 1,
                  pl: 4.5,
                  pr: 2,
                  py: 0.8,
                  fontSize: "0.8rem",
                  outline: "none",
                  width: "100%",
                  "&:focus": {
                    borderColor: "#ddd",
                  },
                },
              }}
            >
              <BiSearch size={20} color="#bbb" id="search-icon" />
              <input
                placeholder={"Rechercher"}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </Box>

            <Box
              sx={{
                maxHeight: 350,
                minHeight: 200,
                px: 1,
                overflowY: "scroll",
                scrollbarWidth: "thin",
              }}
            >
              {status === "LOADING_COINS" ? (
                <LoadingView />
              ) : filteredCoins.length > 0 ? (
                filteredCoins.map((coin) => (
                  <Box
                    key={coin.name}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      px: 2,
                      py: 1,
                      mb: 1.5,
                      borderRadius: 1,
                      border: `2px solid ${selectedCoin?.key === coin.key ? "#ddd" : "transparent"
                        }`,
                      cursor: "pointer",
                      backgroundColor:
                        selectedCoin?.key === coin.key ? "#f4f6f8" : "#fff",
                      "&:hover": {
                        borderColor: "#ddd",
                      },
                    }}
                    onClick={() => {
                      setSelectedCoin(coin);
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Box p={0.4} backgroundColor="#eee" borderRadius={0.8}>
                        <img src={coin.image} alt="" height={24} />
                      </Box>
                      <Typography marginLeft={1.5}>{coin.name}</Typography>
                    </Box>

                    <BiChevronRight size={32} color="#bbb" />
                  </Box>
                ))
              ) : null}
            </Box>
          </>
        ) : !showPendingView ? (
          <Box px={1}>
            <Typography variant="h5" textAlign="center" mb={2}>
              Payer votre commande
            </Typography>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <img
                src={coinpaymentsData.qrcode_url}
                alt=""
                style={{
                  width: "100%",
                  maxWidth: 200,
                  height: "auto",
                }}
              />
            </Box>
            <Box mt={2}>
              <Typography textAlign="center" mb={1}>
                Pour confirmer votre commande, scannez le QR Code ou envoyer{" "}
                {coinpaymentsData.amount} {selectedCoin?.name} à l'adresse
                ci-dessous
              </Typography>
              <Box mb={2}>
                <Typography fontWeight="bold" mb={0.4}>
                  Montant:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    backgroundColor: "#eee",
                    py: 1,
                    px: 2,
                    mb: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography overflow={"hidden"}>
                    {coinpaymentsData.amount} {selectedCoin?.name}
                  </Typography>
                  <CopyWrapper
                    text={coinpaymentsData.amount}
                    onCopyMessage={"Montant copié"}
                  />
                </Box>
              </Box>
              <Box>
                <Typography fontWeight="bold" mb={0.4}>
                  {"Adresse"}:
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    backgroundColor: "#eee",
                    py: 1,
                    px: 2,
                    mb: 1,
                    borderRadius: 1,
                  }}
                >
                  <Typography overflow={"hidden"}>
                    {coinpaymentsData.address}
                  </Typography>
                  <CopyWrapper
                    text={coinpaymentsData.address}
                    onCopyMessage={"Adresse copiée"}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box px={1}>
            <Typography variant="h5" textAlign="center" mb={2}>
              {"Confirmation de paiement"}
            </Typography>
            <Typography className="text-poppins" textAlign="center">
              {"Nous avons détecter votre paiement en attente"}
            </Typography>
            <Box mb={2}>
              <Box
                display="flex"
                justifyContent="center"
                position="relative"
                my={3}
              >
                <CircularProgress variant="determinate" value={50} size={100} />
                <Box
                  sx={{
                    position: "absolute",
                    top: -4,
                    left: "46%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#eee",
                    height: 75,
                    width: 75,
                    borderRadius: "50%",
                    m: 2,
                  }}
                />
              </Box>
              <Typography
                className="text-poppins"
                textAlign="center"
                color="#555"
              >
                {
                  "Il n'y a rien d'autre à faire ici. Vous pouvez fermer cette boîte de dialogue si vous le souhaitez"
                }
              </Typography>
            </Box>
          </Box>
        )}
        <Divider sx={{ my: 0.5 }} />
        <Box display="flex" justifyContent="space-between" mt={3} mx={1}>
          <Box>
            <Typography variant="h6">{total}€</Typography>
          </Box>
          {status === "LOADED_COINS" ? (
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{ px: 4 }}
              disabled={isLoading || !selectedCoin}
            >
              Valider
              {isLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
            </Button>
          ) : (
            <Button
              onClick={() => {
                if (showPendingView) {
                  onClose();
                  router.push(`/parrainage`);
                } else {
                  setShowPendingView(true);
                }
              }}
              variant="contained"
              sx={{ px: 4 }}
              disabled={status === STATUS.LOADING}
            >
              {showPendingView ? "Fermer" : "Confirmer"}
            </Button>
          )}
        </Box>
      </Box>
    </MUIModal>
  );
};

export default CoinPaymentModal;

const LoadingView = () => {
  return (
    <>
      {Array.from({ length: 6 }).map((_, i) => (
        <Box
          key={i}
          sx={{
            p: 3,
            mb: 1.5,
            borderRadius: 1,
            backgroundColor: "#F4F6F8",
          }}
        />
      ))}
    </>
  );
};
