import React, { useState, useRef, useEffect } from "react";
import MUIModal from "../../../Utils/MuiModal";
import {
  useElements,
  useStripe,
  CardElement,
  //AddressElement,
} from "@stripe/react-stripe-js";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import { toast } from "react-toastify";
//import { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import Autocomplete from "@mui/material/Autocomplete";
import useAuth from "../../../../hooks/store/useAuth";
import { addPaymentMethod } from "../../../../api/payments";

export default function AddPaymentModal({
  triggerComponent,
  onSubmit,
  openImediately,
}) {
  const sm = useMediaQuery("(max-width:600px)");

  const stripe = useStripe();
  const elements = useElements();
  const card = useRef();
  const { stripePaymentMethods, setStripePaymentMethods } = useAuth();

  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const cardInfosInitialData = {
    name: "",
    address: {
      line1: "",
      country: ""
    },
  };
  const [cardInfos, setCardInfos] = useState(cardInfosInitialData);

  const [countries, setCountries] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState(null)

  const onCardDataChange = (key, value) => {
    setCardInfos({ ...cardInfos, [key]: value });
  };

  useEffect(() => {
    const loadCountries = () => {
      const lists = Country.getAllCountries();
      setCountries(lists)
    }
    loadCountries()
  }, [])

  //console.log("statesOptions", statesOptions);

  useEffect(() => {
    if (openImediately) {
      setOpen(true);
    }
  }, [openImediately]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //setBillingErrors(null);
  };

  const onNewPaymentMethod = (method) => {
    const methods = [...stripePaymentMethods, method];
    setStripePaymentMethods(methods);
    onSubmit?.(method);
  };

  const handleSubmit = async () => {
    /*  if (billingErrors) {
      setBillingErrors(null);
    } */
    //const addressElement = elements.getElement(AddressElement);
    const cardElement = elements.getElement(CardElement);
    //const billingDetails = await addressElement.getValue();

    //console.log({ cardElement, billingDetails });

    if (cardElement /* && billingDetails.complete */) {
      try {
        setSubmitting(true);
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          //billing_details: billingDetails.value,
          card: cardElement,
          billing_details: {
            name: cardInfos.name,
            address: {
              line1: cardInfos.address.line1,
              country: selectedCountry?.value || null
            },
          },
        });
        if (paymentMethod) {
          const { data } = await addPaymentMethod(paymentMethod);
          if (data.success) {
            onNewPaymentMethod(data.data);
            handleClose();
            setSubmitting(false);
            setCardInfos(cardInfosInitialData);
            toast.success("Méthode de paiement ajoutée avec succès", {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      } catch (error) {
        console.log(error);
        setSubmitting(false);
      }
    } else {
      //setBillingErrors(validationErrors);
      toast.error("Vérifier les informations de la carte", {
        position: "bottom-center",
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };


  const countriesOptions = countries.map(c => ({
    label: c.name,
    value: c.isoCode
  }))

  return (
    <>
      <Box onClick={handleOpen} display="inline-block">
        {triggerComponent}
      </Box>
      <MUIModal maxWidth="sm" open={open} handleClose={handleClose}>
        <Box
          py={2}
          px={sm ? 0 : 4}
          sx={
            {
              /* "& input": {
              width: "100%",
              //height: "35px",
              border: "1px solid #cccccc",
              paddingLeft: "10px",
              borderRadius: "5px",

              "&:focus": {
                outline: "none",
              },
            }, */
            }
          }
        >
          <div>
            <div>
              <Typography
                variant="h5"
                className="text-racing-sans"
                textAlign="center"
                mb={2}
              >
                Méthode de paiement
              </Typography>
              <Box my={1}>
                <label
                  className="text-poppins mb-1"
                  style={{ fontSize: "0.95rem" }}
                >
                  Informations de la carte
                </label>
                <Box
                  sx={{
                    border: "1px solid #cccccc",
                    height: "40px",
                    paddingTop: "8px",
                    paddingLeft: "10px",
                    borderRadius: "5px",
                    marginBottom: "15px",
                    //display: "flex",
                    //alignItems: "center",
                  }}
                >
                  <Box>
                    <CardElement ref={card} />
                  </Box>
                </Box>
              </Box>

              <Box mb={2}>
                <TextField
                  label="Nom du détenteur de la carte"
                  onChange={(e) => {
                    onCardDataChange("name", e.target.value);
                  }}
                  value={cardInfos.name}
                  type="text"
                  name="name"
                  placeholder="Nom du détenteur de la carte"
                  fullWidth
                  size="small"
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label="Adresse"
                  onChange={(e) => {
                    onCardDataChange("address", {
                      ...cardInfos.address,
                      line1: e.target.value,
                    });
                  }}
                  value={cardInfos.address.line1}
                  type="text"
                  name="address"
                  placeholder="Adresse"
                  fullWidth
                  size="small"
                />
              </Box>

              <Box>
                <label
                  className="text-poppins"
                  style={{ fontSize: "0.95rem" }}
                >
                  Pays
                </label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  name="Pays"
                  value={selectedCountry}
                  options={countriesOptions}
                  onChange={(v) => {
                    //console.log({ v })
                    setSelectedCountry(v);
                  }}
                />
              </Box>

              {/* <Box
                sx={{
                  marginBottom: "15px",
                  //display: "flex",
                  //alignItems: "center",
                }}
              >
                <AddressElement
                  options={{
                    mode: "billing",
                    autocomplete: {
                      mode: "google_maps_api",
                      apiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
                    },
                  }}
                />
              </Box> */}

              <div>
                {/* <div className="mb-2">
                  <PlacesSelection
                    value={placeDetails}
                    onSelect={onPlaceSelect}
                  />
                  {billingErrors?.line1 && (
                    <Typography
                      variant="body2"
                      color="error"
                      className="text-poppins"
                    >
                      {billingErrors.line1}
                    </Typography>
                  )}
                </div>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box>
                      <label
                        className="text-poppins"
                        style={{ fontSize: "0.95rem" }}
                      >
                        Pays
                      </label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        name="Pays"
                        value={selectedLocation.country}
                        options={countriesOptions}
                        onChange={handleSelectCountry}
                      />
                      {billingErrors?.country && (
                        <Typography
                          variant="body2"
                          color="error"
                          className="text-poppins"
                        >
                          {billingErrors.country}
                        </Typography>
                      )}
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box>
                      <label
                        className="text-poppins"
                        style={{ fontSize: "0.95rem" }}
                      >
                        Province
                      </label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        name="Province"
                        value={selectedLocation.state}
                        options={statesOptions}
                        onChange={handleSelectState}
                      />
                      {billingErrors?.state && (
                        <Typography
                          variant="body2"
                          color="error"
                          className="text-poppins"
                        >
                          {billingErrors.state}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <label
                    className="text-poppins"
                    style={{ fontSize: "0.95rem" }}
                  >
                    Ville
                  </label>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    name="Ville"
                    value={selectedLocation.city}
                    options={citiesOptions}
                    onChange={handleSelectCity}
                  />
                  {billingErrors?.city && (
                    <Typography
                      variant="body2"
                      color="error"
                      className="text-poppins"
                    >
                      {billingErrors.city}
                    </Typography>
                  )}
                </Box> */}

                <Box mt={4}>
                  <Button
                    variant="outlined"
                    //color="primary"
                    sx={{
                      px: sm ? 4 : 5,
                      mr: 2,
                    }}
                    onClick={handleClose}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      px: sm ? 4 : 5,
                      "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: 0.9,
                      },
                    }}
                    //disabled={submitting}
                    onClick={handleSubmit}
                  >
                    {submitting ? "Validation..." : "Valider"}
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </Box>
      </MUIModal>
    </>
  );
}

export const PlacesSelection = ({ onSelect }) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
    language: "en",
  });

  //console.log("placePredictions", placePredictions);

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (details) => onSelect(details)
      );
  }, [placePredictions]);

  const options = placePredictions.map((place) => ({
    label: place.description,
    value: place.place_id,
  }));

  return (
    <Autocomplete
      loading={isPlacePredictionsLoading}
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={options}
      onChange={(e, value) => {
        console.log("value", value);
        const result = placePredictions.find(
          (place) => place.place_id === value.value
        );
        console.log(result);
        onSelect(result);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Adresse"
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
          onChange={(e) => {
            getPlacePredictions({ input: e.target.value });
            /* setCardInfo((prev) => {
                                  return {
                                    ...prev,
                                    address: { ...prev.address, line: e.target.value },
                                  };
                                }); */
          }}
        />
      )}
    />
  );
};
