import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { createRound, updateRound } from "../../../api/rounds";
import { validateRoundData } from "../../../helpers/validators";
import MUIModal from "../../Utils/MuiModal";
import { toast } from "react-toastify";
import { TOKEN_NAME } from "../../../helpers/data";

export default function RoundForm({ trigerComponent, round, onSubmit }) {
  const [open, setOpen] = useState(false);
  const [roundData, setRoundData] = useState({
    price: "",
    quantity: "",
    stock: "",
  });
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (round && open) {
      setRoundData({
        price: round.price,
        quantity: round.quantity,
        stock: round.quantity
      });
    }
  }, [round, open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors(null);
    setRoundData({
      price: "",
      quantity: "",
    });
  };

  const handleSubmit = async () => {
    const { valid, errors: validationErrors } = validateRoundData(roundData);

    if (!valid) {
      setErrors(validationErrors);
    } else {
      try {
        //if (!round) {
        setSubmitting(true);
        const request = round ? updateRound : createRound;
        const { data } = await request({ ...round, ...roundData });
        if (data.success) {
          onSubmit(data.data);
          toast.success(
            round ? "Round modifié avec succès!" : "Round créé avec succès!",
            {
              position: "bottom-center",
              autoClose: 4000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        setSubmitting(false);
        handleClose();
        //}
      } catch (error) {
        console.log(error);
        setSubmitting(false);
      }
    }
  };

  return (
    <>
      <Box onClick={handleOpen}>{trigerComponent}</Box>

      <MUIModal open={open} handleClose={handleClose}>
        <Box>
          <Typography variant="h6" mb={2}>
            Round{" "}
          </Typography>
          <TextField
            label={`Valeur du ${TOKEN_NAME} en €`}
            className="formfield"
            variant="outlined"
            type="number"
            value={roundData.price}
            onChange={(e) => {
              setRoundData({
                ...roundData,
                price: e.target.value,
              });
            }}
            helperText={errors?.price}
            error={errors?.price}
            fullWidth
            endAdornment={
              <InputAdornment color="white" position="end">
                €
              </InputAdornment>
            }
          />
          <Box my={3}>
            <TextField
              label={`Quantité de ${TOKEN_NAME} à ce prix`}
              className="formfield"
              variant="outlined"
              type="number"
              value={roundData.quantity}
              onChange={(e) => {
                setRoundData({
                  ...roundData,
                  quantity: e.target.value,
                  stock: parseFloat(e.target.value)
                });
              }}
              helperText={errors?.quantity}
              error={errors?.quantity}
              fullWidth
            />
          </Box>
          <Button
            onClick={handleSubmit}
            loading={submitting}
            disabled={submitting}
            variant="contained"
            className="px-md-4 bgGradient"
            sx={{
              mr: 2,
            }}
          >
            {submitting ? "En cours..." : round ? "Modifier" : "Valider"}
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Annuler
          </Button>
        </Box>
      </MUIModal>
    </>
  );
}
