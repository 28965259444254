import React from "react";
import HorizontalLinearStepper from "../component/Achat";
import DashboardLayout from "../component/Dashboard/DashboardLayout";
// import DashboardCompo from "../component/DashboardCompo";
import "./dashboard.css";

export default function Purchase() {
    return (
        <div className="bgImage">
            <DashboardLayout>
                <HorizontalLinearStepper />
            </DashboardLayout>
        </div>
    );
}
