import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function NavItem({ to, icon, text, handleActive = true }) {
    const active = handleActive && to ? (window.location.pathname === to) : false;
    return (
        <Link to={to}>
            <Box display="flex" alignItems="center"
                sx={{
                    px: 2,
                    py: 1,
                    borderRadius: "5px",
                    backgroundColor: active ? "primary.dark" : "transparent",
                    "&:hover": {
                        backgroundColor: active ? "primary.dark" : "transparent",
                        opacity: 0.9,
                    },
                }}>
                {icon}
                <Typography color="white" className="text-poppins" ml={1.5}>
                    {text}
                </Typography>
            </Box>
        </Link>
    );
}

export const Logo = ({ fontSize = "1.2rem" }) => {
    return (
        <div className="d-flex align-items-center justify-content-cente">
            <img src="/logo.svg" alt="logo" />
            <Typography variant="h6" ml={1} className="text-white" fontSize={fontSize}>OKAIWA</Typography>
        </div>
    )
}