import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
//import "./registration.css";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import { Link, useHistory } from "react-router-dom";
import { registerUser } from "../../api/auth";
import { validateSignupData } from "../../helpers/validators";
import { initialize, setAuthHeaders } from "../../redux/auth";
import useAuth from "../../hooks/store/useAuth";
import { Alert, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import useQuery from "../../hooks/useQuery";

export default function Register() {
  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    username: "",
  });

  const [terms, setTerms] = useState({
    cgu: true,
    policies: true,
    documentInformation: true,
    countriesRestriction: true,
  });

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);

  const { user } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const ref = query.get("ref");

  useEffect(() => {
    if (user) {
      history.push("/dashboard");
    }
  }, [user, history]);

  const handleSubmit = async () => {
    //e.preventDefault();
    try {
      if (errors) setErrors(null);
      const { valid, errors: validationErrors } = validateSignupData({
        ...userData,
        ...terms,
      });
      if (!valid) {
        setErrors(validationErrors);
      } else {
        setSubmitting(true);
        if (ref) {
          userData.refererCode = ref;
        }
        const { data } = await registerUser(userData);
        if (data.success) {
          setAuthHeaders(data.data);
          dispatch(initialize());
          //history.push("/dashboard");
          //history.push("/valide");
          setSubmitting(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);

      if (error.response?.data) {
        console.log(error.response.data);
        setErrors({
          general: error.response.data.message,
        });
      }
    }
  };

  return (
    <Box
      className="boxclass bgGradient"
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: "450px",
          width: "100%",
          boxShadow: "0 0 5px rgba(0,0,0,0.1)",
          backgroundColor: "#fff",
          py: 3,
        }}
        className="bloc2 bg-white"
      >
        <div className="form">
          <Box
            component="form"
            sx={{
              //width: "100%",
              px: 3,
            }}
            spacing={3}
            noValidate
            autoComplete="off"
          >
            <Typography
              variant="h4"
              textAlign="center"
              className="text_bien mb-4 text-black"
            >
              Inscription
            </Typography>
            {errors?.general && (
              <Box my={2}>
                <Alert severity="error">{errors.general}</Alert>
              </Box>
            )}
            <TextField
              id="outlined"
              label="Nom"
              value={userData.lastName}
              onChange={(e) => {
                setUserData((data) => ({
                  ...data,
                  lastName: e.target.value,
                }));
              }}
              helperText={errors && errors.lastName}
              error={errors && errors.lastName}
              sx={{
                mb: 2,
              }}
              fullWidth
            />
            <TextField
              id="outlined"
              label="Prénom(s)"
              value={userData.firstName}
              onChange={(e) => {
                setUserData((data) => ({
                  ...data,
                  firstName: e.target.value,
                  username: e.target.value,
                }));
              }}
              helperText={errors && errors.firstName}
              error={errors && errors.firstName}
              sx={{
                mb: 2,
              }}
              fullWidth
            />
            <TextField
              label="Email"
              type="email"
              value={userData.email}
              onChange={(e) => {
                setUserData((data) => ({ ...data, email: e.target.value }));
              }}
              helperText={errors && errors.email}
              error={errors && errors.email}
              sx={{ mb: 2 }}
              fullWidth
            />
            <TextField
              label="Mot de passe"
              type="password"
              value={userData.password}
              onChange={(e) => {
                setUserData((data) => ({
                  ...data,
                  password: e.target.value,
                }));
              }}
              helperText={errors && errors.password}
              error={errors && errors.password}
              sx={{ mb: 2 }}
              fullWidth
            />

            <FormGroup
              className="Formlabe"
              sx={{
                display: "none",
                color: "#fff",
                "& a": {
                  color: "primary.main",
                  fontSize: "14px",
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={terms.cgu}
                    onChange={(_, checked) => {
                      setTerms((data) => ({ ...data, cgu: checked }));
                    }}
                  />
                }
                label={<a href="/#">J'accepte les CGU/CGV</a>}
              />
              {errors?.cgu && (
                <Typography
                  variant="body2"
                  sx={{ color: "#d32f2f" }}
                  textAlign="left"
                >
                  {errors.cgu}
                </Typography>
              )}
              <Link to="/#"> </Link>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={terms.policies}
                    onChange={(_, checked) => {
                      setTerms((data) => ({ ...data, policies: checked }));
                    }}
                  />
                }
                label={
                  <a href="/#">J'accepte la politique de confidentialité</a>
                }
              />
              {errors?.policies && (
                <Typography
                  variant="body2"
                  sx={{ color: "#d32f2f" }}
                  textAlign="left"
                >
                  {errors.policies}
                </Typography>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={terms.documentInformation}
                    onChange={(_, checked) => {
                      setTerms((data) => ({
                        ...data,
                        documentInformation: checked,
                      }));
                    }}
                  />
                }
                label={
                  <a href="/#">
                    J'ai pris connaissance du document d'information
                  </a>
                }
              />
              {errors?.documentInformation && (
                <Typography
                  variant="body2"
                  sx={{ color: "#d32f2f" }}
                  textAlign="left"
                >
                  {errors.documentInformation}
                </Typography>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={terms.countriesRestriction}
                    onChange={(_, checked) => {
                      setTerms((data) => ({
                        ...data,
                        countriesRestriction: checked,
                      }));
                    }}
                  />
                }
                label={
                  <a href="/#">
                    Je ne suis pas resortissant de l'un de ces pays
                  </a>
                }
              />
              {errors?.countriesRestriction && (
                <Typography
                  variant="body2"
                  sx={{ color: "#d32f2f" }}
                  textAlign="left"
                >
                  {errors.countriesRestriction}
                </Typography>
              )}
            </FormGroup>

            <Box textAlign="center" mb={3}>
              <div className="mt-3">
                {/* <Link to="/valide" style={{ textDecoration: "none" }}> */}
                <Button
                  className={`btnReg ${submitting ? "" : "bgGradient"}`}
                  variant="contained"
                  disableElevation
                  onClick={handleSubmit}
                  disabled={submitting}
                  sx={{ px: 5, py: 1.2 }}
                >
                  {submitting ? "Inscription..." : "Inscription"}
                </Button>
                {/* </Link> */}
              </div>
              <Typography variant="h6" my={2}>
                J'ai déjà un compte
              </Typography>
              <div>
                <Link to="/auth/login" style={{ textDecoration: "none" }}>
                  <Button
                    className="btnReg2"
                    variant="contained"
                    disableElevation
                    sx={{ px: 5, py: 1.2 }}
                  >
                    Connexion
                  </Button>
                </Link>
              </div>
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
