import { TRANSACTION_STATUS } from "./data";

export const formatNumber = (number, toFixed) => {
  if (number) {
    //.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    return (
      parseFloat(number.toFixed(toFixed))
        .toString()
        //.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",")
    );
  } else {
    return "";
  }
};

export const formatToFrenchNumber = (number) => {
  // add space between each three digits and replace the decimal point by a comma
  if (number) {
    return number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      .replace(".", ",");
  } else {
    return 0;
  }
};

export const toFixed2 = (value) => {
  if (isNaN(value)) {
    return "-";
  } else if (parseFloat(value)) {
    return parseFloat(value).toFixed(2);
  } else {
    return value
  }
};

export const convertToValidNumber = (number) => {
  if (number) {
    return parseFloat(number.replace(",", "."));
  } else {
    return 0;
  }
};

export const convertEurToLifd = (currencyPrice, tokenPrice) => {
  //console.log("currencyPrice", currencyPrice)
  return currencyPrice / tokenPrice;
};

export const isStableCoin = (symbol) => {
  return (
    symbol.includes("BTC") || symbol.includes("ETH") || symbol.includes("BNB")
  );
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

export const getDeviseFromSymbol = (symbol) => {
  return symbol.split("EUR")[0];
};

export const spreadAttributes = (data) => {
  let value = {
    ...data,
    ...data.attributes,
  };

  delete value.attributes;
  return value;
};

export const calculateUserBalance = (transactions, totalEarnedOnReferrals) => {
  let balance = 0;
  transactions
    .filter((t) => t.status === TRANSACTION_STATUS.VALIDATED)
    .forEach((transaction) => {
      balance += transaction.qty;
    });

  return balance + totalEarnedOnReferrals;
};

export const formatWalletAdress = (address) => {
  return (
    address.substring(0, 5) +
    "..." +
    address.substring(address.length - 4, address.length)
  );
};

export const formatHash = (hash) => {
  return (
    hash.substring(0, 6) + "..." + hash.substring(hash.length - 4, hash.length)
  );
};

export const getUserReferalCodeLink = (referalCode) => {
  return `${window.location.origin}/auth/registration?ref=${referalCode}`;
};

export const calculateRefereeGain = (userGain) => {
  return userGain ? userGain / 0.1 : 0;
};

export const sortDataByCreatedAt = (data) => {
  return data
    .sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.createdAt);
    })
    .reverse();
};

export const formatTransactionStatus = (status) => {
  switch (status) {
    case TRANSACTION_STATUS.VALIDATED:
      return "Validée";
    case TRANSACTION_STATUS.PENDING:
      return "En attente";
    case TRANSACTION_STATUS.REJECTED:
      return "Rejetée";
    default:
      return "";
  }
};

export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const visa = "/cards/visa.png";
const americanexpress = "/cards/americanexpress.png";
const dinersclub = "/cards/dinersclub.jpg";
const discover = "/cards/discover.jpg";
const elo = "/cards/elo.png";
const hiper = "/cards/hiper.png";
const jcb = "/cards/jcb.png";
const mastercard = "/cards/mastercard.png";
const mir = "/cards/mir.png";
const unionpay = "/cards/unionpay.png";

export function getCardImage(type) {
  switch (type) {
    case "visa":
      return visa;
    case "mastercard":
      return mastercard;
    case "amex":
      return americanexpress;
    case "diners club":
      return dinersclub;
    case "discover":
      return discover;
    case "jcb":
      return jcb;
    case "unionpay":
      return unionpay;
    case "maestro":
      return mastercard;
    case "mir":
      return mir;
    case "elo":
      return elo;
    case "hiper":
      return hiper;
    case "hipercard":
      return hiper;
    default:
      return visa;
  }
}
