import React, { useEffect, useRef } from "react";
/* import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper"; */
import "./DashboardCompo.css";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

// import { padding } from '@mui/system';

/* const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
})); */

export default function Buy() {
  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);
  return (
    <div className="gb">
      <div className="container">
        <div className="col-10 container bg-black bar2 p-3 mt-4 ">
          <div style={{ textAlign: "center" }} className="col-md-12 p-4">
            <h1
              style={{ textAlign: "center", fontSize: "50px", color: "white" }}
            >
              <b>Le round est terminé! </b>
            </h1>
            <br /> <br />
            <Link className="linka" to="/transactions">
              <Button
                style={{
                  marginTop: "1px",
                  border: "1px solid white",
                  fontSize: "20px ",
                }}
                className="btnc2"
                variant="contained"
                disableElevation
              >
                voir mes transactions
              </Button>{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
