import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
//import Paper from "@mui/material/Paper";
import "./login.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Link, useHistory } from "react-router-dom";
import { validateLoginData } from "../../helpers/validators";
import { loginUser } from "../../api/auth";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { initialize, setAuthHeaders } from "../../redux/auth";
import useAuth from "../../hooks/store/useAuth";
import { useDispatch } from "react-redux";
import { USER_ROLE } from "../../helpers/data";

export default function Login() {
  const sceneRef = useRef();

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const { user } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  useEffect(() => {
    if (user) {
      history.push(
        user.role === USER_ROLE.ADMIN ? "/admin" : "/dashboard"
      );
    }
  }, [user, history]);

  const handleSubmit = async () => {
    //e.preventDefault();
    try {
      if (errors) setErrors(null);
      const { valid, errors: validationErrors } = validateLoginData(userData);
      if (!valid) {
        setErrors(validationErrors);
      } else {
        setSubmitting(true);
        const { data } = await loginUser(userData);
        if (data.success) {
          setAuthHeaders(data.data);
          dispatch(initialize());
          //history.push("/dashboard");
          setSubmitting(false);
        }
      }
    } catch (error) {
      console.error(error);
      setSubmitting(false);

      if (error.response?.data) {
        console.log(error.response.data);
        setErrors({
          general: error.response.data.message,
        });
      }
    }
  };

  return (
    <Box className="boxclass bgGradient" sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          maxWidth: "450px",
          width: "100%",
          boxShadow: "0 0 5px rgba(0,0,0,0.1)",
          py: 2
        }}
        className="bloc2 bg-white"
      >
        <div className="form">
          <Stack
            component="form"
            sx={{
              width: "100%",
              py: 2,
              px: 3,
            }}
            spacing={3}
            noValidate
            autoComplete="off"
            justifyContent="center"
          >
            <Typography
              variant="h4"
              textAlign="center"
              className="text_bien"
            >
              Connexion
            </Typography>
            {errors?.general && (
              <Box my={1}>
                <Alert severity="error">{errors.general}</Alert>
              </Box>
            )}
            <TextField
              label="Email"
              value={userData.email}
              onChange={(e) => {
                setUserData((data) => ({ ...data, email: e.target.value }));
              }}
              helperText={errors && errors.email}
              error={errors && errors.email}
            />
            <TextField
              id="outlined"
              label="Mot de passe"
              variant="outlined"
              type="password"
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
              helperText={errors && errors.password}
              error={errors && errors.password}
            />
            <Typography component={Link} to="/auth/forgot-password" color="primary">
              Mot de passe oublié ?
            </Typography>
            <div className="center">
              <Button
                className={`btnLog ${submitting ? "" : "bgGradient"}`}
                variant="contained"
                disableElevation
                onClick={handleSubmit}
              //disabled={submitting}
              >
                {submitting ? "Connexion..." : "Connexion"}
                {submitting && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "white",
                      ml: 1
                    }}
                  />
                )}
              </Button>
            </div>
            <br />
            <Typography className="text-center" variant="h6">Vous n'avez pas encore de compte ?</Typography>
            <div className="center1">
              <Link
                className="link1"
                to="/auth/registration"
                style={{ textDecoration: "none" }}
              >
                <Button
                  className="btnLogin bgGradient"
                  variant="contained"
                  disableElevation
                >
                  Inscription
                </Button>
              </Link>
            </div>
          </Stack>
        </div>
      </Box>
    </Box>
  );
}
