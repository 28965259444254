import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PAYMENT_METHODS, TOKEN_NAME } from "../../helpers/data";
import { useState } from "react";
import { Button, Divider } from "@mui/material";
import useRounds from "../../hooks/store/useRounds";
import { BiChevronRight } from "react-icons/bi";
import CoinPaymentModal from "../Payments/Coinpayments/Index";
import useDisclosure from "../../hooks/useDisclose";
import { PaymentMethodsModal } from "../Payments/Stripe/PaymentMethodsList/Lists";
import { PaymentFormModal } from "../Payments/Stripe/PaymentForm/Index";
import { createPaymentIntent } from "../../api/payments";
import { useHistory } from "react-router-dom";
import { KkiapayTrigger } from "../Payments/Kkiapay/Index";
import { useCallback } from "react";
import { toFixed2 } from "../../helpers";

export default function Purchase() {
  const {
    isOpen: isCPModalOpen,
    onOpen: onCPModalOpen,
    onClose: onCPModalClose,
  } = useDisclosure();
  const {
    isOpen: isCBModalOpen,
    onOpen: onCBModalOpen,
    onClose: onCBModalClose,
  } = useDisclosure();

  const {
    isOpen: isCBPaymentModalOpen,
    onOpen: onCBPaymentModalOpen,
    onClose: onCBPaymentModalClose,
  } = useDisclosure();

  const router = useHistory();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentIntent, setPaymentIntent] = useState();

  //const [qty, setQty] = useState(0);
  const [total, setTotal] = useState(0);

  const [isPurchasing, setIsPurchasing] = useState(false);

  const { rounds } = useRounds();
  const round = rounds.data.find((r) => r.stock > 0);
  const tokenPrice = round?.price || 0;

  const qty = total ? total / tokenPrice : 0;

  const paymentData = { qty, amount: total };

  const onStripePaymentDone = () => {
    router.push(`/dashboard`);
  };

  const onCBSelect = useCallback(async (method) => {
    try {
      //console.log({ method });
      const intentData = {
        amount: paymentData.amount,
        qty: paymentData.qty,
        paymentMethodId: method.id,
        roundId: round._id
      };
      //setPaymentIntent(intentData)
      setSelectedPaymentMethod(method);
      const { data } = await createPaymentIntent(intentData);
      //console.log("PAYMENT INT", data);

      if (data.success) {
        setPaymentIntent(data.data);
        onCBModalClose();
        onCBPaymentModalOpen();
      }
    } catch (error) {
      console.log(error);
    }
  }, [paymentData, round]);


  return (
    <div>
      <div className="row bg-light py-5 px-md-4">
        <Box className="col-md-4">
          <Box sx={{ p: 4, borderRadius: 3 }} className="__bg-gradient">
            <Typography variant="h6" className="text-white">
              Combien de {TOKEN_NAME} voulez-vous acheter ?
            </Typography>
            <Typography className="text-white">
              Vous pouvez saisir le montant que vous souhaitez payer
            </Typography>
          </Box>
        </Box>
        <Box className="col-md-5">
          <Box>
            <Box
              sx={{
                p: 4,
                borderRadius: 3,
                "& input": {
                  border: "1px solid #eee",
                  backgroundColor: "#fff",
                  borderRadius: 1,
                  p: 2,
                  fontSize: "1rem",
                  outline: "none",
                  width: "100%",
                  "&:focus": {
                    borderColor: "#ddd",
                  },
                },
              }}
              className="bg-white py-5 border"
            >
              <Box>
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={1}
                >
                  <Typography variant="h6" fontSize="1.1rem">
                    Montant en €
                  </Typography>
                  <Typography variant="h6" fontWeight={"bold"}>
                    {`${toFixed2(qty)} ${TOKEN_NAME}`}
                  </Typography>
                </Box>
                <input
                  id="total"
                  type="number"
                  placeholder="200"
                  min="100"
                  required
                  value={total}
                  onChange={(e) => setTotal(parseFloat(e.target.value))}
                />
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Box>
                  <Typography
                    textAlign="center"
                    variant="h6"
                    fontSize="1.3rem"
                    mb={1.5}
                  >
                    Sélectionner une méthode de paiement
                  </Typography>

                  {/* <Box
                    onClick={() => {
                      if (total) {
                        setSelectedPaymentMethod(PAYMENT_METHODS.CREDIT_CARD);
                        onCBModalOpen();
                      }
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      p: 2,
                      mb: 2,
                      borderRadius: 1,
                      cursor: total ? "pointer" : "not-allowed",
                      border: "1px solid #eee",
                      backgroundColor: "#fff",
                      "&:hover": {
                        borderColor: "#ccc",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      borderRadius={2}
                      color="#777"
                      className="text-poppins"
                    >
                      Carte de crédit
                    </Typography>
                    <Box>
                      <img
                        src="/cards/visa.png"
                        alt=""
                        style={{
                          height: 15,
                          width: "auto",
                          marginRight: 5,
                        }}
                      />
                      <img
                        src="/cards/mastercard.png"
                        alt=""
                        style={{
                          height: 28,
                          width: "auto",
                          marginRight: 3,
                        }}
                      />
                      <BiChevronRight size={32} color="#777" />
                    </Box>
                  </Box> */}
                  <Box
                    onClick={() => {
                      if (total) {
                        setSelectedPaymentMethod(PAYMENT_METHODS.CRYPTO);
                        onCPModalOpen();
                      }
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexWrap: "wrap",
                      p: 2,
                      mb: 2,
                      borderRadius: 1,
                      cursor: total ? "pointer" : "not-allowed",
                      border: "1px solid #f4f6f8",
                      backgroundColor: "#fff",
                      "&:hover": {
                        borderColor: "#ccc",
                      },
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      borderRadius={2}
                      color="#777"
                      className="text-poppins"
                    >
                      Cryptomonnaie
                    </Typography>
                    <Box>
                      <img
                        src="/cards/BTC.png"
                        alt=""
                        style={{
                          height: 26,
                          width: "auto",
                          marginRight: 5,
                        }}
                      />
                      <img
                        src="/cards/ETH.png"
                        alt=""
                        style={{
                          height: 26,
                          width: "auto",
                          marginRight: 5,
                        }}
                      />
                      <img
                        src="/cards/BNB.png"
                        alt=""
                        style={{
                          height: 26,
                          width: "auto",
                          marginRight: 4,
                        }}
                      />
                      <BiChevronRight size={32} color="#777" />
                    </Box>
                  </Box>
                  {/* <KkiapayTrigger
                    total={total}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                  />
 */}
                </Box>
                <>
                  {selectedPaymentMethod === PAYMENT_METHODS.CRYPTO ? (
                    <CoinPaymentModal
                      qty={qty}
                      total={total}
                      isOpen={isCPModalOpen}
                      onClose={onCPModalClose}
                      roundId={round._id}
                      onBack={() => {
                        setSelectedPaymentMethod("");
                      }}
                    />
                  ) : selectedPaymentMethod === PAYMENT_METHODS.CREDIT_CARD ? (
                    <PaymentMethodsModal
                      isOpen={isCBModalOpen}
                      onClose={onCBModalClose}
                      qty={qty}
                      total={total}
                      onSelect={onCBSelect}
                      isSubmitting={isPurchasing}
                    />
                  ) : null}

                  <PaymentFormModal
                    open={isCBPaymentModalOpen}
                    handleClose={onCBPaymentModalClose}
                    onSubmit={onStripePaymentDone}
                    paymentIntent={paymentIntent}
                    selectedPaymentMethod={selectedPaymentMethod}
                  />
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
}
