import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRounds as getRoundsAction,
  addOrUpdateRound as addOrUpdateRoundAction,
  deleteRound as deleteRoundAction,
} from "../../redux/rounds";

const useRounds = () => {
  const { rounds, status, activeRound } = useSelector((state) => state.rounds);

  const dispatch = useDispatch();

  const getRounds = useCallback(() => {
    dispatch(getRoundsAction());
  }, [dispatch]);

  const addOrUpdateRound = useCallback(
    (data) => {
      dispatch(addOrUpdateRoundAction(data));
    },
    [dispatch]
  );

  const deleteRound = (data) => {
    dispatch(deleteRoundAction(data));
  };

  return {
    activeRound,
    rounds,
    status,
    getRounds,
    addOrUpdateRound,
    deleteRound,
  };
};

export default useRounds;
