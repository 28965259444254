import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencies as getCurrenciesAction } from "../../redux/currencies"

const useCurrencies = () => {
    const { data, status, tokenPrice, totalTokens } = useSelector(state => state.currencies);

    const dispatch = useDispatch();

    const getCurrencies = useCallback(() => {
        dispatch(getCurrenciesAction());
    }, [dispatch])

    return {
        currencies: data,
        status,
        getCurrencies,
        tokenPrice,
        totalTokens
    }
}

export default useCurrencies 