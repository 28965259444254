import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { spreadAttributes } from "../../../helpers";
import { CURRENCIES, STATUS } from "../../../helpers/data";
import useWallets from "../../../hooks/store/useWallets";
import { LoadingCard } from "../../Utils/Loaders";
import WalletCard from "./WalletCard";
import WalletForm from "./WalletForm";

export default function Wallets() {
  const { wallets, status } = useWallets();

  /*   useEffect(() => {
          getWallets();
      }, [getWallets]); */

  const sortedWallets = wallets.data.slice().sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });

  return (
    <Box backgroundColor="#f4f6f8" p={3}>
      <Typography variant="h5" color="#222">
        Wallets
      </Typography>
      <Box>
        {status === STATUS.LOADING ? (
          <Grid container spacing={3} mt={0.4}>
            {Array.from({ length: 3 }).map((_, i) => (
              <Grid key={i} item lg={3} md={4} xs={12}>
                <LoadingCard />
              </Grid>
            ))}
          </Grid>
        ) : wallets.data.length > 0 ? (
          <Grid container spacing={3} mt={0.4}>
            {sortedWallets.map((w, i) => {
              const wallet = spreadAttributes(w);
              const currency = CURRENCIES.find(
                (c) => c.name === wallet.libelle
              );
              return (
                <Grid key={i} item lg={3} md={4} xs={12}>
                  <WalletCard wallet={wallet} image={currency?.image} />
                </Grid>
              );
            })}
            {CURRENCIES.length > wallets.data.length && (
              <Grid item lg={3} md={4} xs={12}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    height: "100%",
                    py: 3,
                    borderRadius: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <WalletForm
                    trigerComponent={
                      <Button
                        sx={{
                          textDecoration: "none",
                          fontSize: 12,
                          px: 2,
                          py: 1,
                        }}
                        className="bgGradient"
                        variant="contained"
                        disableElevation
                      >
                        Ajouté une autre adresse
                      </Button>
                    }
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        ) : (
          <>
            <Box
              sx={{
                backgroundColor: "#fff",
                height: "100%",
                py: 3,
                mt: 2,
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <WalletForm
                trigerComponent={
                  <Button
                    sx={{ textDecoration: "none", fontSize: 12, px: 2, py: 1 }}
                    className="bgGradient"
                    variant="contained"
                    disableElevation
                  >
                    Ajouté une adresse
                  </Button>
                }
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
