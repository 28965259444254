import {
  Box,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import React from "react";
import { STATUS } from "../../../helpers/data";
import { sortDataByCreatedAt } from "../../../helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function UsersLists({
  users,
  status,
  page,
  handlePageChange,
  setSelectedUser,
  onUpdate,
  onDelete,
}) {

  const onWalletCopied = () => {
    toast.success("Adresse copiée!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div>
      {users.data.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#fff", color: "white", mt: 4 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="text-black">Date</TableCell>
                <TableCell className="text-black">Nom et Prénoms</TableCell>
                <TableCell className="text-black">Email</TableCell>
                <TableCell className="text-black">Transactions</TableCell>
                <TableCell className="text-black">Wallets</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortDataByCreatedAt(users.data).map((user, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell
                      component="th"
                      scope="row"
                      className="text-black"
                    >
                      {moment(user.createdAt).format("DD/MM/YYYY hh:mm:ss")}
                    </TableCell>
                    <TableCell>
                      <Typography className="text-black">
                        {user.firstName} {user.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="text-black">
                        {user.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Typography className="text-black" mr={1}>
                          {user.transactions?.length}
                        </Typography>
                        {user.transactions?.length ? <RemoveRedEyeIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setSelectedUser(user);
                          }}
                        /> : null}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography className="text-black">
                        {user.walletAddress || "-"}
                        {user.walletAddress && (
                          <CopyToClipboard
                            text={user.walletAddress}
                            onCopy={onWalletCopied}
                          >
                            <ContentCopyIcon
                              sx={{
                                fontSize: 16,
                                display: "inline-block",
                                ml: 0.2,
                                cursor: "pointer",
                              }}
                            />
                          </CopyToClipboard>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* 
        {users.data.length === 0 && (
          <Typography textAlign="center" m={3}>
            Aucune user effectuée
          </Typography>
        )} */}
        </TableContainer>
      ) : (
        status === STATUS.LOADED ? (
          <Typography textAlign="center" m={3}>
            Aucun utilisateur trouvé
          </Typography>
        ) : null
      )}
      {status === STATUS.LOADING && (
        <Box
          color="white"
          textAlign="center"
          my={3}
          display="flex"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
      {users.totalPages && users.totalPages > 1 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 3,
          }}
        >
          <Pagination
            count={users.totalPages}
            page={page}
            onChange={handlePageChange}
            size="small"
            sx={{
              "& .css-hnd4u0-MuiButtonBase-root-MuiPaginationItem-root": {
                color: "white",
              },
            }}
          />
        </Box>
      ) : null}
    </div>
  );
}
