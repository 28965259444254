import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import { formatToFrenchNumber } from "../../../helpers";
import { TOKEN_NAME } from "../../../helpers/data";
import BtnWithTooltip from "../../Utils/BtnWithTooltip";
import DeleteRound from "./DeleteRound";
import RoundForm from "./RoundForm";

export default function RoundCard({ round, number, onEdit, onDelete }) {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        px: 3,
        py: 2,
        borderRadius: 2,
        position: "relative",
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        Round {number}
      </Typography>
      <Typography sx={{ fontSize: 16 }}>
        <strong>Prix:</strong> {formatToFrenchNumber(round.price)}€
      </Typography>
      <Typography sx={{ fontSize: 16 }}>
        <strong>Quantité:</strong> {formatToFrenchNumber(round.quantity)} {TOKEN_NAME}
      </Typography>
      <Typography sx={{ fontSize: 16 }}>
        <strong>En Stock:</strong> {formatToFrenchNumber(round.stock)} {TOKEN_NAME}
      </Typography>
      <RoundForm
        round={round}
        trigerComponent={
          <Box sx={{ position: "absolute", top: 8, right: 8 }}>
            <BtnWithTooltip tip="Modifier">
              <EditOutlined sx={{ color: "primary.main" }} fontSize="small" />
            </BtnWithTooltip>
          </Box>
        }
        onSubmit={onEdit}
      />
      <DeleteRound
        onDelete={onDelete}
        triggerComponent={
          <Box sx={{ position: "absolute", top: 40, right: 8 }}>
            <BtnWithTooltip tip="Supprimer">
              <DeleteOutline color="error" fontSize="small" />
            </BtnWithTooltip>
          </Box>
        }
        round={round}
      />
    </Box>
  );
}
