import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const LoadingCard = () => {
  return (
    <SkeletonTheme color="#f9f9f9" highlightColor="#f9f9f9">
      <Skeleton className="w-100 bg-white" height={100} />
    </SkeletonTheme>
  );
};
