import "bootstrap/dist/css/bootstrap.min.css";
import Welcome from "./component/Welcome";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardCompo from "./component/DashboardCompo";
import DashboardHome from "./pages/dashboard";
import Transactionshom from "./pages/transactions";
import ParrainageHom from "./pages/parrainage";
import PurchaseCoins from "./pages/buy";
import Valide from "./component/Valide";
// import Achat from "./component/Achat";
import PurchasePage from "./pages/achat";
import Login from "./pages/auth/login";
import { ThemeProvider } from "@mui/material";
import theme from "./helpers/theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import Register from "./pages/auth/register";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Initializer } from "./component/Utils/Initializer";
import AdminMain from "./pages/admin";
import Transactions from "./pages/admin/transactions";
import axios from "axios";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPassword from "./pages/auth/resetPassword";
import Users from "./pages/admin/users";
import Purchase from "./pages/purchase";
import SocketIoProvider from "./context/socketIo";
import StripeWrapper from "./component/Payments/Stripe/Wrapper";
import Vestings from "./pages/vestings";
import { WalletConnectProvider } from "./component/Utils/WalletConnectProvider";

// @mui material components

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Welcome />
        </Route>
        <Route exact path="/auth/login">
          <Login />
        </Route>
        <Route exact path="/auth/registration">
          <Register />
        </Route>
        <Route exact path="/auth/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/auth/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/dashboardCompo">
          <DashboardCompo />
        </Route>
        <Route exact path="/dashboard">
          <DashboardHome />
        </Route>
        <Route exact path="/transactions">
          <Transactionshom />
        </Route>
        <Route exact path="/vestings">
          <Vestings />
        </Route>
        <Route exact path="/parrainage">
          <ParrainageHom />
        </Route>
        <Route exact path="/buy">
          <PurchaseCoins />
        </Route>
        <Route exact path="/p">
          <Purchase />
        </Route>
        <Route exact path="/valide">
          <Valide />
        </Route>
        <Route exact path="/achat">
          <PurchasePage />
        </Route>
        <Route exact path="/admin">
          <AdminMain />
        </Route>
        <Route exact path="/admin/transactions">
          <Transactions />
        </Route>
        <Route exact path="/admin/users">
          <Users />
        </Route>
        {/* <Route exact path="/achat">
      
      <Achat />
    </Route> */}
      </Switch>
    </Router>
  );
};

function App() {
  //create an axios interceptor for error status 401 and redirect to login page
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        //window.location.href = "/auth/login";
      }
      return Promise.reject(error);
    }
  );

  return (
    <StripeWrapper>
      <WalletConnectProvider>
        <SocketIoProvider>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <Initializer>
                <ToastContainer />

                <Routes />
              </Initializer>
            </ThemeProvider>
          </Provider>
        </SocketIoProvider>
      </WalletConnectProvider>
    </StripeWrapper>
  );
}

export default App;
