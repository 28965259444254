import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import "./bienvenue.css";
import Button from "@mui/material/Button";
//import img from "../images/img.png";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { Logo } from "./Dashboard/NavItem";

// import LI5D4D from '../images/LI5D4D.mp4'

export default function Bienvenue() {
  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);
  return (
    <Box className="boxclass bgGradient" sx={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box>
        <div className="d-flex justify-content-center">
          <Logo fontSize="1.5rem" />
        </div>
        <Box
          sx={{
            mt: 2,
            maxWidth: "400px",
            width: "100%",
            boxShadow: "0 0 5px rgba(0,0,0,0.1)",
            py: 2,
            "@media (min-width: 600px)": {
              width: "400px",
            }
          }}
          className="bloc2 bg-white rounded"
        >
          <div className="bg2">
            <Typography variant="h3" textAlign="center" fontWeight="bold" className="text-center text-black">Bienvenue</Typography>
            <Typography
              variant="h5"
              textAlign="center"
              className="text"
            >
              Première Visite
            </Typography>

            <div className="center mt-3">
              <Link to="/auth/registration" style={{ textDecoration: "none" }}>
                <Button
                  className="btnLogin bgGradient"
                  variant="contained"
                  disableElevation
                  px={2}
                >
                  Inscription
                </Button>
              </Link>
            </div>
            <Typography variant="h6" className="text2 mt-4">J'ai déjà un compte</Typography>
            <div className="center1 mt-2">
              <Link to="/auth/login" style={{ textDecoration: "none" }}>
                <Button
                  className="btnLogin2"
                  variant="contained"
                  disableElevation
                >
                  Connexion
                </Button>
              </Link>
            </div>
            {/* <img src={img} className='imageBk' alt='img'  /> */}
          </div>
        </Box >
      </Box>
    </Box >
  );
}
