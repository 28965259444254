import { Box } from "@mui/material";
import React from "react";
import SideBar from "./DashboardSideBar";
import { styled } from "@mui/material/styles";
import "./DashboardLayout.css";
import ProtectedView from "../Utils/ProtectedView";

export default function DahboordLayout({ children }) {
  return (
    <ProtectedView>
      <Box
        className="bg"
        sx={{
          py: 4,
          px: 3.4,
          "@media (min-width: 992px)": {
            pr: 4.5,
          },
        }}
      >
        <SideBar />
        <DahboardMain>{children}</DahboardMain>
      </Box>
    </ProtectedView>
  );
}

const StyledDashbordMain = styled(Box)(
  `margin-left: 320px;

@media (max-width: 992px) {
  margin-left: 0;
}`
);

const DahboardMain = ({ children }) => {
  return <StyledDashbordMain p={0}>{children}</StyledDashbordMain>;
};
