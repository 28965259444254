import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { STATUS } from '../../helpers/data';
import useAuth from '../../hooks/store/useAuth';
import LoadingView from './LoadingView';


const ProtectedView = ({ children }) => {
    const { status, user, isAuthenticated } = useAuth();

    const history = useHistory();

    //console.log(status, isAuthenticated)

    useEffect(() => {
        if (status !== STATUS.LOADING && !isAuthenticated) {
            history.push('/auth/login');
        }
        const token = sessionStorage.getItem("token");
        if (!token) {
            history.push('/auth/login');
        }

    }, [isAuthenticated, status, history]);

    /*     const setAuthData = useCallback((user) => {
            setAuthenticated(user);
            setStatus(STATUS.LOADED);
        }, [setAuthenticated, setStatus])
    
        useEffect(() => {
            let token = sessionStorage.getItem("token");
            setAuthHeaders(token);
            let user = sessionStorage.getItem("user");
            user = JSON.parse(user);
            if (!user) {
                history.push("/auth/login");
            } else {
                //console.log(user, token)
                setAuthData(user)
            }
        }, [history, setAuthData]); */


    return (
        <>
            {status === STATUS.LOADED && user ? (
                <>
                    {children}
                </>
            ) : (
                <LoadingView />
            )}
        </>
    );
}

export default ProtectedView