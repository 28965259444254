import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Nav } from "react-bootstrap";
import "./DashboardSideBar.css";
import { createSvgIcon } from "@mui/material/utils";
import Drawer from "@mui/material/Drawer";
import { Link, useHistory } from "react-router-dom";
import RestoreIcon from "@mui/icons-material/Restore";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/icons-material/Menu";
import useAuth from "../../hooks/store/useAuth";
import { TOKEN_NAME, USER_ROLE } from "../../helpers/data";
import SettingsIcon from "@mui/icons-material/Settings";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import GroupIcon from "@mui/icons-material/Group";
import VestingIcon from "@mui/icons-material/TransferWithinAStationRounded";
import NavItem from "./NavItem";
import EditWallet from "./EditWallet";

const StyledSideBar = styled(Box)(
  `
height: 100vh;
position: fixed;
top: 1.8rem;
z-index: 9;
  @media (max-width: 992px) {
    display: none;

  }`
);
const HomeIcon = createSvgIcon(
  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />,
  "Home"
);

const StyledMobileNavBar = styled(Box)(
  `display: none;
  @media (max-width: 992px) {
    display: flex;
    justify-content: space-between;
    background-color: #3f74fd;
    margin: -2rem -1.7rem 2.4rem;
    padding: 0.6rem;
  }
  `
);

export default function SideBar() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledSideBar>
        <SideBarContent />
      </StyledSideBar>
      <StyledMobileNavBar>
        <Menu
          onClick={toggleDrawer}
          className="text-white"
          sx={{ cursor: "pointer" }}
          fontSize="large"
        />
        <Box>
          <div className="d-flex align-items-center justify-content-cente">
            <img src="/logo.svg" alt="logo" />
            <Typography variant="h6" ml={1} className="text-white" fontSize="1.2rem">OKAIWA</Typography>
          </div>
        </Box>
        <Box sx={{ p: 2 }} />
      </StyledMobileNavBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <SideBarContent />
      </Drawer>
    </>
  );
}

const ADMIN_MENU_ITEMS = [
  {
    to: "/admin",
    icon: <SettingsIcon style={{ color: "#fff" }} />,
    text: "Paramètres",
  },
  {
    to: "/admin/transactions",
    icon: <CurrencyExchangeIcon style={{ color: "#fff" }} />,
    text: "Transactions",
  },
  {
    to: "/admin/users",
    icon: <GroupIcon style={{ color: "#fff" }} />,
    text: "Utilisateurs",
  },
];

const CLIENT_MENU_ITEMS = [
  {
    to: "/dashboard",
    icon: <HomeIcon style={{ color: "#fff" }} />,
    text: "Dashboard",
  },
  {
    to: "/transactions",
    icon: <RestoreIcon style={{ color: "#fff" }} />,
    text: "Transactions",
  },
  /* {
    to: "/vestings",
    icon: <VestingIcon style={{ color: "#fff" }} />,
    text: "Vestings",
  }, */
  {
    to: "/parrainage",
    icon: <GroupIcon style={{ color: "#fff" }} />,
    text: "Parrainage",
  },
];

const SideBarContent = () => {
  const { logoutUser, user } = useAuth();
  const history = useHistory();

  const isAdmin = user?.role === USER_ROLE.ADMIN;

  const handleLogout = () => {
    logoutUser();
    history.push("/auth/login");
  };

  return (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#3f74fd",
        height: "100%",
        "@media (min-width: 992px)": {
          borderRadius: 2,
        },
      }}
    >
      <Box p={2} pt={3}>
        <div className="logo d-flex align-items-center justify-content-cente">
          <img src="/logo.svg" alt="logo" />
          <Typography variant="h6" ml={1} className="text-white text-poppins" fontSize="1.2rem">OKAIWA</Typography>
        </div>
      </Box>

      <div>
        {!isAdmin ? (
          <>
            <Nav
              style={{ marginTop: "-30px" }}
              defaultActiveKey="/home"
              className="flex-column p-4 pt-0"
            >
              <Nav.Link
                style={{
                  textAlign: "center",
                  color: "white",
                  borderRadius: "5px",
                  backgroundColor: "#80a4ff",
                }}
              >
                <Link
                  to="/achat"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <Typography color="white" className="text-poppins">Acheter ${TOKEN_NAME}</Typography>
                </Link>
              </Nav.Link>
              <div
                style={{ marginTop: "20px", marginLeft: -14, marginRight: -14 }}
                className="linkNemu text-white"
              >
                <Box>
                  {CLIENT_MENU_ITEMS.map(({ to, icon, text }, i) => (
                    <Box mb={1} key={i} px={1}>
                      <NavItem
                        to={to}
                        icon={icon}
                        text={text}
                        handleActive={false}
                      />
                    </Box>
                  ))}
                  <EditWallet />
                </Box>

              </div>
            </Nav>
          </>
        ) : (
          <Box px={2}>
            {ADMIN_MENU_ITEMS.map(({ to, icon, text }, i) => (
              <Box mb={1} key={i}>
                <NavItem to={to} icon={icon} text={text} />
              </Box>
            ))}
          </Box>
        )}

        <Box
          sx={{
            textAlign: "center",
            borderRadius: "5px",
            cursor: "pointer",
            mx: 2,
            mt: isAdmin ? 8 : 0,
            p: 1,
            backgroundColor: "#bf4342",
            color: "#fff"
          }}

        >
          <Typography onClick={handleLogout}>
            <LogoutIcon style={{ marginRight: "10px" }} />
            Déconnexion
          </Typography>
        </Box>
      </div>
    </Box>
  );
};
