import { createSlice } from "@reduxjs/toolkit";
import { getWallets as fetchWallets } from "../api/wallets";
import { spreadAttributes } from "../helpers";
import { STATUS } from "../helpers/data";

export const wallets = createSlice({
  name: "wallets",
  initialState: {
    wallets: {
      data: [],
    },
    status: "",
  },
  reducers: {
    setWallets: (state, action) => {
      state.wallets = {
        ...action.payload,
        data: action.payload.map(spreadAttributes),
      };
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    addOrUpdateWallet: (state, action) => {
      const payload = spreadAttributes(action.payload);
      const { _id } = payload;
      const wallet = state.wallets.data.find((w) => w._id === _id);
      if (wallet) {
        state.wallets.data[state.wallets.data.indexOf(wallet)] = payload;
      } else {
        state.wallets.data.push(payload);
      }
    },
    addWallet: (state, action) => {
      state.wallets.data.push(action.payload);
    },
    updateWallet: (state, action) => {
      state.wallets.data = state.wallets.data.map((wallet) => {
        if (wallet._id === action.payload._id) {
          return action.payload;
        }
        return wallet;
      });
    },
    deleteWallet: (state, action) => {
      state.wallets.data = state.wallets.data.filter(
        (wallet) => wallet._id !== action.payload
      );
    },
  },
});

export const {
  setWallets,
  setStatus,
  addWallet,
  updateWallet,
  deleteWallet,
  addOrUpdateWallet,
} = wallets.actions;
export default wallets.reducer;

export const getWallets = () => async (dispatch) => {
  //if (wallets.data.length === 0) {
  dispatch(setStatus(STATUS.LOADING));
  //}
  try {
    const { data } = await fetchWallets();
    if (data.success) {
      //console.log("cur", data)
      dispatch(setWallets(data.data));
      dispatch(setStatus(STATUS.LOADED));
    }
  } catch (error) {
    dispatch(setStatus(STATUS.ERROR));
  }
};
