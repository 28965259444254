import React, { useEffect, useRef } from "react";
//import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
//import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./bienvenue.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// import img from "../images/img.png";
import { Link } from "react-router-dom";

// import LI5D4D from '../images/LI5D4D.mp4'

/* const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
})); */

export default function Valide() {
  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);
  return (
    <Box sx={{ backgroundColor: "rgb(41, 41, 41)", minHeight: "100vh" }} className="boxclass">
      <Grid className="boxContainer" container spacing={0} columns={12}>
        <Grid className="bloc1" item xs={4}>
          <div className="bg1">
            <video
              ref={(el) => (sceneRef.current = el)}
              src="/LI5D4D.mp4"
              autoPlay
              playsInline
              loop
              controls={false}
              muted
              style={{ width: "90%", height: "100%", objectFit: "contain" }}
            />
          </div>
        </Grid>

        <Grid
          sx={{ backgroundColor: "rgb(30, 30, 30)" }}
          className="bloc2"
          item
          xs={4}
        >
          <div style={{ marginTop: "150px" }} className="bg2">
            <Typography
              style={{ textAlign: "center", marginBottom: "-6px", fontSize: "20px" }}
              className="text-white"
            >
              Veuillez valider
            </Typography>
            <Typography variant="h2" style={{ marginTop: "0", fontWeight: "bold" }} className="text_bienv">
              Votre Identité{" "}
            </Typography>
            <Typography className="text-white mt-4" style={{ textAlign: "center" }}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown
            </Typography>

            <Box sx={{ mt: 3 }} className="center">
              <Link style={{ textDecoration: "none" }} to="">
                <Button
                  className="btnLogin"
                  variant="contained"
                  disableElevation
                  sx={{ px: 6 }}

                >
                  Valider mon LIFD
                </Button>{" "}
              </Link>
            </Box>

            <div className="center1 mt-2">
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <Button
                  className="btnLogin2"
                  variant="contained"
                  disableElevation
                >
                  Tableau de bord
                </Button>
              </Link>
            </div>
          </div>
        </Grid>

        <Grid className="bloc3" item xs={4}>
          <div className="bg3">
            <video
              ref={(el) => (sceneRef.current = el)}
              src="/Composition 1_1.mp4"
              autoPlay
              playsInline
              loop
              controls={false}
              muted
              style={{ width: "90%", height: "100%", objectFit: "contain" }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
