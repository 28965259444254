import axios from "axios";
import { API_BASE_URL } from "../helpers/data";

export const getTransactions = (params) => {
  return axios.get(`${API_BASE_URL}/transactions`, {
    params,
  });
};

export const extractTransactions = (params = {}) => {
  return axios.get(`${API_BASE_URL}/transactions/extract`, {
    params,
  });
};

export const createTransaction = (data) => {
  return axios.post(`${API_BASE_URL}/transactions`, data);
};

export const updateTransactionStatus = (id, data) => {
  return axios.put(`${API_BASE_URL}/transactions/${id}/status`, data);
};

export const getUserBalance = (params) => {
  return axios.get(`${API_BASE_URL}/transactions/balance`, { params });
};
