import { useState } from "react";

import clsx from "clsx";
import {
  useStripe,
  CardCvcElement,
  useElements,
} from "@stripe/react-stripe-js";
import MUIModal from "../../../Utils/MuiModal";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { confirmPaymentIntent } from "../../../../api/payments";

export const PaymentForm = ({
  onSubmit,
  selectedPaymentMethod,
  paymentIntent,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  //const { paymentIntent, selectedPaymentMethod } = usePayment();

  const [cvcError, setCvcError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { card, billing_details } = selectedPaymentMethod;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    stripe
      .createToken("cvc_update", elements.getElement(CardCvcElement))
      .then(async (result) => {
        if (result.error) {
          setCvcError(result.error.message);
        } else {
          const { data } = await confirmPaymentIntent({
            paymentMethodId: selectedPaymentMethod.id,
            paymentIntentId: paymentIntent.id,
          });
          if (data.success) {
            handleServerResponse(data.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        /* Handle error*/
      });
  };

  const handleServerResponse = (response) => {
    if (response.error) {
      /* Handle Error */
    } else if (response.next_action) {
      handleAction(response);
    } else {
      setIsSubmitting(false);
      toast.success("Paiement effectué avec succès", {
        position: "bottom-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onSubmit?.(response);
      /* Handle Success */
      //window.location.reload();
    }
  };

  const showErrorToast = () => {
    toast.error("Une erreur est survenue", {
      position: "bottom-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleAction = (response) => {
    try {
      stripe.handleCardAction(response.client_secret).then(async (result) => {
        if (result.error) {
          console.log(result.error);
          showErrorToast();
          /* Handle error */
        } else {
          const { data } = await confirmPaymentIntent({
            paymentMethodId: selectedPaymentMethod.id,
            paymentIntentId: paymentIntent.id,
          });
          if (data.success) {
            handleServerResponse(data.data);
          }
        }
      });
    } catch (error) {
      console.log(error);
      showErrorToast();
    }
  };

  return (
    card && (
      <Box
        sx={{
          px: 3,
          ".wrapper": {
            form: {
              ".card": {
                border: "1px solid #d1d1d1",
                borderRadius: 5,
                position: "relative",
                width: 500,
                ".icon": {
                  img: {
                    width: 80,
                    height: "auto",
                  },
                  position: "absolute",
                  right: 20,
                },
              },

              ".row": {
                marginBottom: 20,
                label: {
                  display: "block",
                  marginBottom: 5,
                  fontSize: 14,
                  color: "#7d7d7d",
                },
              },
              ".row.col": {
                display: "flex",
                "div:first-child": {
                  marginRight: 100,
                },
              },
            },
          },
          ".cvcError": {
            fontSize: 12,
            color: "red",
            marginTop: 5,
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">Confirmation de paiement</Typography>
          <Box p={2} mt={1} mb={2} className={"card"}>
            <div className={"icon"}>
              <img src={card.icon} alt="" />
            </div>
            <div className={"row"}>
              <label>Nom du détenteur</label>
              <p>{billing_details.name}</p>
            </div>
            <div className={clsx("row", "col")}>
              <div className={"cardNumber"}>
                <label>Numéro de la carte</label>
                <p>{`**** **** **** ${card.last4}`}</p>
              </div>
              <div className={"expiry"}>
                <label>Expire</label>
                <p>
                  {moment(
                    new Date(`${card.exp_year}/${card.exp_month}/01`)
                  ).format("mm/yyyy")}
                </p>
              </div>
            </div>

            <div className={"row"}>
              <label>Entrer le Cvc/Cvv </label>
              <Box
                sx={{
                  border: "1px solid #d4d4d4",
                  width: 180,
                  borderRadius: 1,
                  padding: 1,
                  mt: 0.5,
                  ml: 1,
                }}
              >
                <CardCvcElement
                  onChange={() => {
                    setCvcError(null);
                  }}
                />
              </Box>
              <p className={"cvcError"}>{cvcError}</p>
            </div>
          </Box>
          <Button
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            className="__bg-gradient text-white"
            mt={3}
          >
            {isSubmitting ? "Confirmation..." : "Confirmer le paiement"}
            {isSubmitting && (
              <CircularProgress sx={{ color: "white" }} size={24} />
            )}
          </Button>
        </form>
      </Box>
    )
  );
};

export const PaymentFormModal = ({
  open,
  handleClose,
  onSubmit,
  paymentIntent,
  selectedPaymentMethod,
}) => {
  return (
    <MUIModal open={open} handleClose={handleClose}>
      <PaymentForm
        paymentIntent={paymentIntent}
        onSubmit={onSubmit}
        selectedPaymentMethod={selectedPaymentMethod}
      />
    </MUIModal>
  );
};
