import React, { useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import "./DashboardCompo.css";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useAuth from "../hooks/store/useAuth";
import moment from "moment";
import { TOKEN_NAME, TRANSACTION_STATUS } from "../helpers/data";
import {
  formatHash,
  formatToFrenchNumber,
  formatTransactionStatus,
} from "../helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function Transactions() {
  const { user, refreshUserData } = useAuth();
  const transactions = [...user.transactions];
  /*   const sortedTransactions = transactions.sort((a, b) => {
      //console.log(a.createdAt, b.createdAt);
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    }); */

  //console.log("user", user)

  const sceneRef = useRef();
  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.loop = true;
      sceneRef.current.play(); /// force webkit browsers to play
    }
  }, [sceneRef]);

  useEffect(() => {
    refreshUserData();
  }, [refreshUserData]);

  const onHashCopied = () => {
    toast.success("Hash copié!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="gb">
      <div className="container ">
        <div
          style={{ backgroundColor: "#f4f6f8", borderRadius: "8px" }}
          className="row p-3"
        >
          <Typography variant="h5" className="col-md-4 mt-2 d-md-block d-none">
            Transactions
          </Typography>

          {transactions.length === 0 && (
            <>
              <div
                style={{ textAlign: "center", marginTop: "20px" }}
                className="col-md-4"
              >
                <img height={150} src={"/logo-blue.svg"} alt="" />
                <br /> <br /> <br />
                <Typography style={{ fontSize: "20px" }}>
                  {" "}
                  Oh, aucune transaction trouvée.
                </Typography>
                <Box my={4}>
                  <Button
                    component={Link}
                    to="/achat"
                    className="bgGradient"
                    sx={{
                      color: "#fff",
                      display: "inline-block",
                      px: 3,
                      py: 1.3,
                      borderRadius: 2,
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    Acheter {TOKEN_NAME}
                  </Button>
                </Box>
              </div>

              <div
                className="col-md-4 mt-2"
                style={{ textAlign: "right", color: "white" }}
              ></div>
            </>
          )}
        </div>
      </div>

      {transactions.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{ backgroundColor: "#fff", color: "white", mt: 4 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell /* align="right" */>Quantité</TableCell>
                <TableCell /* align="right" */>Prix {TOKEN_NAME}</TableCell>
                <TableCell>Méthode de paiement</TableCell>
                <TableCell /* align="right" */>Total</TableCell>
                {/* <TableCell >Hash</TableCell> */}
                <TableCell /* align="right" */>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((transaction, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {moment(transaction.createdAt).format(
                        "DD/MM/YYYY hh:mm:ss"
                      )}
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(transaction.qty)}
                      </Typography>{" "}
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(transaction.tokenPrice)}€
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography textTransform={"capitalize"}>
                        {transaction.paymentMethod}
                      </Typography>
                    </TableCell>
                    <TableCell /* align="right" */>
                      {" "}
                      <Typography>
                        {formatToFrenchNumber(transaction.amount)}€
                      </Typography>{" "}
                    </TableCell>
                    {/* <TableCell >
                      <CopyToClipboard
                        text={transaction.hash}
                        onCopy={onHashCopied}
                      >
                        <Typography sx={{ cursor: "pointer" }}>
                          {formatHash(transaction.hash)}
                          <ContentCopyIcon
                            sx={{
                              fontSize: 16,
                              display: "inline-block",
                              ml: 0.2,
                            }}
                          />
                        </Typography>
                      </CopyToClipboard>
                    </TableCell> */}
                    <TableCell /* align="right" */>
                      {" "}
                      <Box
                        className="badge"
                        sx={{
                          backgroundColor:
                            transaction.status === TRANSACTION_STATUS.PENDING
                              ? "orange"
                              : transaction.status ===
                                TRANSACTION_STATUS.VALIDATED
                              ? "green"
                              : "red",
                          color: "white",
                          p: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {formatTransactionStatus(transaction.status)}{" "}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
