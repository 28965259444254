import { useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/store/useAuth";

export function ProtectedByRole({ children, role, onRedirect }) {
    const { user } = useAuth();
    const history = useHistory();

    const canShow = useMemo(() => {
        if (user?.role === role) {
            return true;
        }
        return false;
    }, [user?.role, role]);

    useEffect(() => {
        if (!canShow) {
            let destination = onRedirect(user?.role ?? null);
            history.push(destination);
        }
    }, [user?.role, canShow, history, onRedirect]);

    return <>
        {canShow && children}
    </>
}