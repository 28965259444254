import axios from "axios";
import { API_BASE_URL } from "../helpers/data";

export const getRounds = () => {
  return axios.get(`${API_BASE_URL}/rounds?sort=createdAt:asc`);
};

export const createRound = (data) => {
  return axios.post(`${API_BASE_URL}/rounds`, data);
};

export const updateRound = (data) => {
  return axios.put(`${API_BASE_URL}/rounds/${data._id}`, data);
};

export const deleteRound = (id) => {
  return axios.delete(`${API_BASE_URL}/rounds/${id}`);
};

export const getActiveRound = () => {
  return axios.get(`${API_BASE_URL}/rounds/active`);
};
