import { formatToFrenchNumber } from ".";
import { TOKEN_NAME } from "./data";

export const isEmpty = (string) => !string || (string && string.trim() === "");

export const isEmail = (email) => (email.match(/.+@.+\..+/) ? true : false);

export const isValidNumber = (num) => num && !isNaN(parseInt(num, 10));

export const validateSignupData = (data) => {
  const {
    firstName,
    lastName,
    email,
    password,
    cgu,
    policies,
    documentInformation,
    countriesRestriction,
  } = data;
  const errors = {};

  if (isEmpty(firstName)) errors.firstName = "Le prénom est obligatoire";
  if (isEmpty(lastName)) errors.lastName = "Le nom est obligatoire";

  if (isEmpty(email)) {
    errors.email = "Entrez votre email";
  } else if (!isEmail(email)) {
    errors.email = "Adresse email invalide";
  }
  if (isEmpty(password)) errors.password = "Entrez votre mot de passe";
  if (!isEmpty(password) && password.length < 6)
    errors.password = "Doit contenir au moins 6 caractères";

  if (!cgu) errors.cgu = "Vous devez accepter les CGU/CGV";
  if (!policies)
    errors.policies = "Vous devez accepter la politique de confidentialité";
  if (!documentInformation)
    errors.documentInformation =
      "Vous devez prendre connaissance du document d'information";
  if (!countriesRestriction)
    errors.countriesRestriction =
      "Confirmez que vous n'êtes pas en violation de la loi sur les pays";
  /*   if (!isLogin && password !== confirmPassword) {
      errors.confirmPassword = 'Les mots de passe ne sont pas égaux';
    } */
  /*   if (!category || isEmpty(category))
        errors.category = "La catégorie est obligatoire"; */
  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateLoginData = (data) => {
  const { email, password } = data;
  const errors = {};

  if (isEmpty(email)) {
    errors.email = "Entrez votre email";
  } else if (!isEmail(email)) {
    errors.email = "Adresse email invalide";
  }
  if (isEmpty(password)) errors.password = "Entrez votre mot de passe";
  /*   if (!isLogin && password !== confirmPassword) {
      errors.confirmPassword = 'Les mots de passe ne sont pas égaux';
    } */
  /*   if (!category || isEmpty(category))
        errors.category = "La catégorie est obligatoire"; */
  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateResetPasswordData = (data) => {
  const { passwordConfirmation, password } = data;
  const errors = {};

  if (isEmpty(password)) errors.password = "Entrez le nouveau mot de passe";
  if (password !== passwordConfirmation) {
    errors.passwordConfirmation = "Confirmer le mot de passe";
  }
  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateTransactionData = (data) => {
  const { hash, currency, qty, amount } = data;
  console.log(data);
  const errors = {};
  const _qty = qty.toString();

  if (isEmpty(hash)) errors.hash = "Le hash est obligatoire";
  if (isEmpty(currency)) errors.currency = "Sélectionner une devise";
  if (isEmpty(_qty))
    errors.qty = `Sélectionner la quantité de ${TOKEN_NAME} à acheter devise"`;
  if (isEmpty(amount)) errors.amount = "La quantité est obligatoire";

  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateRoundData = (data) => {
  const { price, quantity } = data;
  const errors = {};

  if (!isValidNumber(price)) errors.price = "Veuillez entre un prix valide";
  if (!isValidNumber(quantity))
    errors.quantity = "Veuillez entre une quantité valide";

  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateWalletData = (data) => {
  const { libelle, address } = data;
  const errors = {};

  if (isEmpty(libelle)) errors.libelle = "Veuillez choisir la devise";
  if (isEmpty(address)) errors.address = "Veuillez entre une adresse valide";

  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateAmountSelectionData = (data) => {
  const { qty, totalQuantity } = data;
  const errors = {};

  const _qty = qty.replace(",", ".");
  if (parseFloat(_qty) > totalQuantity)
    errors.qty = `Alerte! Il ne reste que ${formatToFrenchNumber(
      totalQuantity
    )} ${TOKEN_NAME}`; //"Stock insuffisant";

  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};

export const validateCardBillingData = (data) => {
  const { country, state, city, line1 } = data;
  const errors = {};

  if (isEmpty(country)) errors.country = "Entrez un pays valide";
  if (isEmpty(state)) errors.state = "Entrez votre province ou département";
  if (isEmpty(city)) errors.city = "Entrez votre ville";
  if (isEmpty(line1)) errors.line1 = "L'adresse est obligatoire";

  return {
    valid: Object.keys(errors).length > 0 ? false : true,
    errors,
  };
};


export const isValidWalletAddress = (walletAddress) => {
  if (!walletAddress) {
    return false
  }
  // Ethereum address regex pattern
  const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;

  // Check if the address matches the pattern and has the correct length
  return addressRegex.test(walletAddress) && walletAddress.length === 42;
}
