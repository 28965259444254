import { Box, Typography } from "@mui/material";
import React from "react";
import BtnWithTooltip from "../../Utils/BtnWithTooltip";
import WalletForm from "./WalletForm";
import { EditOutlined } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { formatWalletAdress } from "../../../helpers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

export default function WalletCard({ wallet, image }) {
  const onWalledAdressCopied = () => {
    toast.success("Addresse copiée!", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <Box backgroundColor="#fff" position="relative" p={3} borderRadius={2}>
      <Box display="flex" alignItems="center" mb={1}>
        <img src={image} alt="Currency-logo" height={30} />
        <Typography ml={1}>
          {wallet.libelle}
        </Typography>
      </Box>
      <CopyToClipboard text={wallet.address} onCopy={onWalledAdressCopied}>
        <Typography sx={{ cursor: "pointer" }}>
          {formatWalletAdress(wallet.address)}
          <ContentCopyIcon
            sx={{ fontSize: 16, display: "inline-block", ml: 0.2 }}
          />
        </Typography>
      </CopyToClipboard>
      <WalletForm
        wallet={wallet}
        trigerComponent={
          <Box sx={{ position: "absolute", top: 8, right: 8 }}>
            <BtnWithTooltip tip="Modifier">
              <EditOutlined sx={{ color: "primary.main" }} fontSize="small" />
            </BtnWithTooltip>
          </Box>
        }
      />
    </Box>
  );
}
