import { configureStore } from "@reduxjs/toolkit";
import currencies from "./currencies";
import auth from "./auth";
import wallets from "./wallets";
import rounds from "./rounds";

export default configureStore({
    reducer: {
        currencies,
        auth,
        wallets,
        rounds
    }
});
