import { useEffect } from "react";
import { useSocketIo } from "../context/socketIo";

const useSocketIoListener = (eventName, callback) => {
  const socket = useSocketIo();

  useEffect(() => {
    socket.on(eventName, callback);
    return () => {
      socket.off(eventName);
    };
  }, [eventName, callback]);

  return null;
};

export default useSocketIoListener;
