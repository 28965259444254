import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { SOCKET_URL } from "../helpers/data";
//import { eventSocketConnected } from "../helpers/events";

const Context = createContext();

export default function SocketIoContext({ children }) {
  const [socket, setSocket] = useState();

  //console.log("SOCKET ===>", socket);

  useEffect(() => {
    if (!socket) {
      const token = sessionStorage.getItem("token");
      const _socket = io(SOCKET_URL, {
        //autoConnect: false,
        auth: {
          token,
        },
      });
      setSocket(_socket);
      //_socket.connect();
    }
  }, [socket]);

  return (
    <Context.Provider value={socket}>
      {/* socket &&  */ children}
    </Context.Provider>
  );
}

export const useSocketIo = () => {
  const socket = React.useContext(Context);
  if (socket === undefined) {
    throw new Error("useSocketIo must be used within a socketProvider");
  }
  return socket;
};
