/* global BigInt */
import React, { useCallback, useEffect, useMemo } from "react";
import {
    useAccount,
    useReadContracts,
    useWriteContract,
    useSwitchChain,
    useWaitForTransactionReceipt,
    useDisconnect
} from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
//import { Button, Card, CardBody } from "reactstrap";
import VestingABI from "../../blockhain/VestingABI.json";
import { formatEther } from "viem";
import { Card, Box, Button, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material"
import ProfileIcon from "@mui/icons-material/Wallet"
import LogoutIcon from "@mui/icons-material/Logout";
import { isValidWalletAddress } from "../../helpers/validators";
import useAuth from "../../hooks/store/useAuth";


export default function UserVestings() {
    document.title = "Vesting($OKA)";
    const account = useAccount();
    const chain = account.chain;
    const { switchChain } = useSwitchChain();
    const web3Modal = useWeb3Modal();
    const { disconnect } = useDisconnect()
    const { user } = useAuth()

    useEffect(() => {
        if (chain !== process.env.REACT_APP_VESTING_CHAIN_ID) {
            switchChain?.(
                { chainId: parseInt(process.env.REACT_APP_VESTING_CHAIN_ID ?? "0") }
            );
        }
    }, [chain]);

    if (!process.env.REACT_APP_VESTING_CONTRACT) {
        throw new Error("REACT_APP_VESTING_CONTRACT is not set");
    }

    const contract = {
        abi: VestingABI,
        address: process.env.REACT_APP_VESTING_CONTRACT,
    };
    const { data, error, isLoading, refetch } = useReadContracts({
        contracts: [
            {
                ...contract,
                functionName: "getDeposits",
                args: [user.walletAddress],
            },
            {
                ...contract,
                functionName: "VESTING_DURATION",
            },
        ],
    });
    //const vestingCliff = data?.[1].result?.toString();
    //console.log({ data })
    const vestingDuration = data?.[1].result?.toString();

    const { writeAsync, data: claimData } = useWriteContract({
        ...contract,
        functionName: "claim",
        args: [],
    });

    const { data: claimResult } = useWaitForTransactionReceipt({
        hash: claimData?.hash,
    });

    const deposits = (data?.[0].result) ?? [];

    const depositsPerMonth = [];

    const now = new Date();
    const nowPlusOneMonth = new Date(now);
    nowPlusOneMonth.setMonth(nowPlusOneMonth.getMonth() + 1);
    const nowPlusTwoMonths = new Date(now);
    nowPlusTwoMonths.setMonth(nowPlusTwoMonths.getMonth() + 2);
    const secondsInMonths = (seconds) => seconds / 30.4375 / 24 / 60 / 60;
    // const monthsInSeconds = (months: number) => months * 30.4375 * 24 * 60 * 60;
    // const blockingMonths = secondsInMonths(parseInt(vestingCliff));
    const vestingMonths = secondsInMonths(parseInt(vestingDuration));
    const perMonthPercentage = 100 / vestingMonths;
    deposits.forEach((deposit, i) => {
        const claimed = BigInt(deposit.claimed);
        let claimedLeft = claimed;
        for (let i = 0; i < vestingMonths; i++) {
            const perMonth = BigInt(
                Math.round((perMonthPercentage / 100) * parseFloat(deposit.amount))
            );
            const claimableLeft =
                perMonth - (claimedLeft > perMonth ? perMonth : claimedLeft);
            claimedLeft -= perMonth;

            //console.log(deposit.start, vestingCliff)
            const date = new Date(
                (parseInt(deposit.start)) * 1000
            );
            date.setMonth(date.getMonth() + i + 1);
            const datePlusOneMonth = new Date(date);
            datePlusOneMonth.setMonth(datePlusOneMonth.getMonth() + 1);
            //? If the deposit is more than 1 month further than now, skip it
            if (date.getTime() > nowPlusOneMonth.getTime()) {
                depositsPerMonth.push({
                    date: date.toISOString(),
                    amount: Math.round(
                        (perMonthPercentage / 100) * parseFloat(deposit.amount)
                    ),
                    name: "Okaiwa cashback",
                    unlockPercentage: "0",
                    claimed: false,
                });
            } else if (nowPlusOneMonth.getTime() > datePlusOneMonth.getTime()) {
                depositsPerMonth.push({
                    date: date.toISOString(),
                    amount: Math.round(
                        (perMonthPercentage / 100) * parseFloat(deposit.amount)
                    ),
                    name: "Okaiwa cashback",
                    unlockPercentage: "100",
                    claimed: claimableLeft > 0 ? false : true,
                });
            } else {
                //? The percentage of the month that has passed
                const monthPercentage =
                    (nowPlusOneMonth.getTime() - date.getTime()) /
                    1000 /
                    60 /
                    60 /
                    24 /
                    30.4375;
                // const globalUnlockPercentage = Math.round(
                //   (100 / vestingMonths) * (i + monthPercentage)
                // );
                // const startOfMonthPercentage = Math.round((100 / vestingMonths) * i);
                // const unlockPercentage =
                //   globalUnlockPercentage - startOfMonthPercentage;
                depositsPerMonth.push({
                    date: date.toISOString(),
                    amount: Math.round(
                        (perMonthPercentage / 100) * parseFloat(deposit.amount)
                    ),
                    name: "Okaiwa cashback",
                    unlockPercentage: Math.round(monthPercentage * 100).toString(),
                    claimed: claimableLeft > 0 ? false : true,
                });
            }
        }
    });

    const handleWrite = async () => {
        await writeAsync();
    };

    useEffect(() => {
        refetch();
    }, [claimResult]);
    /*   const isNetworkCorrect = useMemo(() => {
          return Number(account?.chainId) === Number(process.env.REACT_APP_VESTING_CHAIN_ID)
      }, [account?.chainId]) */

    const disconnectWallet = useCallback(() => {
        disconnect()
    }, [disconnect])

    return (
        <div>
            <div
                style={{ backgroundColor: "#f4f6f8", borderRadius: "8px", mx: 6 }}
                className="p-3"
            >
                <Typography variant="h5" className="col-md-4 my-2 d-md-block d-none">
                    Vesting ICO
                </Typography>

                <Box className="poppins" sx={{ display: "flex", alignItems: "center", my: 1 }}>
                    <ProfileIcon />
                    <Typography variant="h6" fontSize={14} ml={1}>{user.walletAddress}</Typography>
                </Box>
                {/* {account?.address ?
                    (<Box>
                        <Box className="poppins" sx={{ display: "flex", alignItems: "center", my: 1 }}>
                            <ProfileIcon />
                            <Typography variant="h6" fontSize={14} ml={1}>{account.address}</Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="error"
                            sx={{
                                maxWidth: 250
                            }}
                            onClick={disconnectWallet}
                        >
                            <LogoutIcon style={{ marginRight: "10px" }} />

                            Déconnecter wallet
                        </Button>
                    </Box>) : (
                        !account.isConnected ? (
                            <Button
                                onClick={() => web3Modal.open()}
                                className="mb-4 text-white bgGradient"
                                sx={{ px: 8 }}
                            >
                                Wallet Connect
                            </Button>
                        ) : null
                    )
                } */}

            </div>

            {/* !isNetworkCorrect && (
                <Box sx={{
                    mt: 1,
                    p: 1,
                    border: '2px solid #bf4342|',
                    backgroundColor: "#ffe5ec",
                    borderRadius: 1
                }}>
                    <Button
                        onClick={() => {
                            switchChain?.(
                                { chainId: parseInt(process.env.REACT_APP_VESTING_CHAIN_ID ?? "0") }
                            )
                        }}
                        variant="outlined"
                        key={"switch"}
                        color="error"
                    >
                        Connectez-vous au réseau Polygon
                    </Button>
                </Box>
            ) */}

            <div>
                <Card>
                    <div>
                        {isValidWalletAddress(user.walletAddress) && (
                            <TableContainer
                                component={Paper}
                                sx={{ backgroundColor: "#fff", color: "white", mt: 0.5 }}
                            >
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell /* align="right" */>POOL</TableCell>
                                            <TableCell /* align="right" */>$OKA</TableCell>
                                            <TableCell /* align="right" */>UNLOCK</TableCell>
                                            <TableCell /* align="right" */>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {depositsPerMonth
                                            .sort((a, b) => {
                                                return (
                                                    new Date(a.date).getTime() - new Date(b.date).getTime()

                                                );
                                            })
                                            .map((deposit, index) => (
                                                <TableRow key={index}>
                                                    {/* <div key={index} className={styles.row}> */}
                                                    <TableCell className={""}>
                                                        {deposit.date.substring(0, 10)}
                                                    </TableCell>
                                                    <TableCell className={""}>{deposit.name}</TableCell>
                                                    <TableCell className={""}>
                                                        {Math.round(
                                                            parseFloat(formatEther(BigInt(deposit.amount))) *
                                                            100
                                                        ) / 100}{" "}
                                                        $OKA
                                                    </TableCell>
                                                    <TableCell>
                                                        <div
                                                            className={
                                                                deposit.unlockPercentage === "100" &&
                                                                    deposit.claimed
                                                                    ? "d-none"
                                                                    : "progress animated-progess progress-lg"
                                                            }
                                                        >
                                                            <div
                                                                className={`progress-bar bg-${deposit.unlockPercentage === "100"
                                                                    ? "success"
                                                                    : "warning"
                                                                    }`}
                                                                role="progressbar"
                                                                style={{
                                                                    width: deposit.unlockPercentage + "%",
                                                                    color: "black",
                                                                }}
                                                                aria-valuenow="69"
                                                                aria-valuemin="0"
                                                                aria-valuemax="100"
                                                            >
                                                                {deposit.unlockPercentage}%
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                                                        {deposit.unlockPercentage === "100" ? (
                                                            deposit.claimed ? (
                                                                <p className="d-flex align-items-center">
                                                                    <i className="bx bx-check me-1 font-size-20"></i>
                                                                    <span>Claimed</span>
                                                                </p>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={() => handleWrite()}
                                                                >
                                                                    Claim
                                                                </button>
                                                            )
                                                        ) : (
                                                            <p>
                                                                <i className="bx bx-lock-alt"></i>
                                                                <span>Locked</span>
                                                            </p>
                                                        )}
                                                    </TableCell>
                                                    {/* </div> */}
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
}
