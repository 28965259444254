//MUI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
//icons
import CloseIcon from "@mui/icons-material/Close";

export default function MUIModal({
  open,
  handleClose,
  children,
  maxWidth = "sm",
  ...props
}) {
  return (
    <Dialog
      {...props}
      open={open}
      onClose={handleClose}
      id
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogContent className="relative">
        {children}
        <CloseIcon
          onClick={handleClose}
          sx={{ position: "absolute", top: "1rem", right: "1rem" }}
          className="pointer"
        />
      </DialogContent>
    </Dialog>
  );
}
