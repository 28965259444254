import { useDispatch, useSelector } from "react-redux";
import {
  logoutUser as logoutUserAction,
  setAuthUser,
  setAuthStatus,
  setAuthenticated as setAuthenticatedAction,
  addUserTransaction,
  refreshUserData as refreshUserDataAction,
  fetchUserBalance as fetchUserBalanceAction,
  setStripePaymentMethods as setStripePaymentMethodsAction,
} from "../../redux/auth";
import { useCallback } from "react";

const useAuth = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const logoutUser = () => {
    dispatch(logoutUserAction());
  };
  const setUser = (data) => {
    dispatch(setAuthUser(data));
  };

  const setAuthenticated = (data) => {
    dispatch(setAuthenticatedAction(data));
  };

  const setStatus = (data) => {
    dispatch(setAuthStatus(data));
  };

  const addTransaction = (data) => {
    dispatch(addUserTransaction(data));
  };

  const refreshUserData = useCallback(() => {
    dispatch(refreshUserDataAction());
  }, [dispatch]);

  const fetchUserBalance = () => {
    dispatch(fetchUserBalanceAction());
  };

  const setStripePaymentMethods = (data) => {
    dispatch(setStripePaymentMethodsAction(data));
  };

  return {
    ...auth,
    logoutUser,
    setUser,
    setStatus,
    setAuthenticated,
    addTransaction,
    refreshUserData,
    fetchUserBalance,
    setStripePaymentMethods,
  };
};

export default useAuth;
