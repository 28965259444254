import React, { useCallback } from "react";

const useFormValues = (initialValue = {}) => {
    const [data, setData] = React.useState(initialValue);

    const setFormValue = (key, value) => {
        //console.log(`${key}---${value}`);
        setData((d) => ({
            ...d,
            [key]: value,
        }));
    };

    /* Change form data with input's name and value */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const resetData = useCallback(() => {
        setData({ ...initialValue });
    });

    return {
        data,
        setData,
        setFormValue,
        handleChange,
        resetData,
    };
};

export default useFormValues

